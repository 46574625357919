import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';

@Component({
   selector: 'app-underconstuction',
   templateUrl: './underconstuction.component.html',
   styleUrls: ['./underconstuction.component.scss']
})
export class UnderconstuctionComponent implements OnInit, OnDestroy {

  isDisabledQuickBooking = true;
   constructor(
   ) { }
   ngOnInit(): void {
   }
   ngOnDestroy(): void { }
}
