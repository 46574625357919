import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { API_ENDPOINT_STAGING } from '../../../shared/helpers';

@Injectable({ providedIn: 'root' })
export class ConfigService
{
  constructor(private apiService: ApiService) { }

  getAll()
  {
    return this.apiService.get(`${API_ENDPOINT_STAGING}/api/app/config`);
  }
  getPaymentConfig()
  {
    return this.apiService.get(`${API_ENDPOINT_STAGING}/api/get_paymentconfig`);
  }
}
