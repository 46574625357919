import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { Observable } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { MovieAds, MovieInfo2 } from '../../../core/models';
import { MovieService, MetaService } from '../../../core/services/http';
import { ShareService } from 'src/app/shared/services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-movie-info',
  templateUrl: './movie-info.component.html',
  styleUrls: ['./movie-info.component.scss']
})
export class MovieInfoComponent implements OnInit, OnDestroy {
  // tslint:disable-next-line:variable-name
  @ViewChild(SlickCarouselComponent, { static: false }) private _$ads: SlickCarouselComponent;
  movieModel: object;

  constructor(
    private $uri: ActivatedRoute,
    private $movieService: MovieService,
    private translate: TranslateService,
    public metaService: MetaService,
    public shareService: ShareService
  ) { }

  readonly slideConfig = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000
  };

  id$: Observable<number | string>;
  model$: Observable<MovieInfo2>;
  ads$: Observable<MovieAds[]>;
  collapsed = {
    synopsis: true,
    crew: true
  };
  adsCount = 0;
  adsIndex = 0;
  movieCode:String = "";


  ngOnInit(): void {
    this.id$ = this.$uri.paramMap.pipe(
      map(u => u.get('id')),
      shareReplay(1)
    );
    this.model$ = this.id$.pipe(
      switchMap(u => this.$movieService.read(u)),
      map(u => {
        if (u.trailerUrl) {
          const url = new URL(u.trailerUrl);
          url.searchParams.set('autoplay', '1');
          url.searchParams.set('mute', '1');

          u.trailerUrl = url.href;
        }
        return u;
      }),
      shareReplay(1)
    );

    // //console.log(this.model$);
    this.model$.subscribe(data => {
      this.metaService.addOGTags(data, window.location.href);
    });
    this.ads$ = this.$movieService.listAds().pipe(
      shareReplay(1)
    );

    this.ads$.subscribe(u => this.adsCount = u.length);

    this.model$.subscribe(data => {
      this.movieModel = {
        title: data.title,
        image: data.trailer,
        description: ''
      };
      this.metaService.addOGTags(this.movieModel, window.location.href);
    });
  }

  ngOnDestroy(): void { }


  onAdsChange(): void {
    this.adsIndex = this._$ads.currentIndex;
  }

  onAdsJump(index: number): void {
    this._$ads.slickGoTo(index);
  }

  onAdsPrev(): void {
    this._$ads.slickPrev();
  }

  onAdsNext(): void {
    this._$ads.slickNext();
  }

  share() {
    this.model$.subscribe(data => {
      ////console.log(data);
      if (this.translate.currentLang === 'en') {
        this.movieModel = {
          title: data.title,
          image: data.trailer,
          description: ''
        };
      } else {
        this.movieModel = {
          title: data.titleKH,
          image: data.trailer,
          description: ''
        };
      }
      this.metaService.addOGTags(this.movieModel, window.location.href);
    });

    this.id$.subscribe(data => {
      this.shareService.share('https://www.majorplatinumcineplex.la/share_movie/'+data);
    });
  }
}
