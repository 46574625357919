import { Component, EventEmitter, OnDestroy, OnInit, Renderer2,Inject, PLATFORM_ID } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { DOCUMENT } from '@angular/common';
import { startWith } from 'rxjs/operators';
import { ApiService } from '../../../core/services/api.service';
import { API_ENDPOINT_STAGING } from '../../../shared/helpers';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-ads-popup',
  templateUrl: './ads-popup.component.html',
  styleUrls: ['./ads-popup.component.scss']
})
export class AdsPopupComponent implements OnInit, OnDestroy
{
  visibleChange = new EventEmitter<boolean>(true);

  constructor(
    private $renderer: Renderer2,
    private $apiService: ApiService,
    @Inject(DOCUMENT) private $document: Document,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  model: {
    image: string,
    imageWebMobile: string,
    link: string,
    on: number
  };
  visible: boolean;


  ngOnInit(): void
  {
    this.visibleChange.asObservable().pipe(
      startWith(false)
    ).subscribe(u =>
    {
      this.visible = u;

      this.$renderer.removeClass(this.$document.body, 'modal-open');

      if (u) {
        this.$renderer.addClass(this.$document.body, 'modal-open');
      }
    });
    if (isPlatformBrowser(this.platformId)) {
      this.$apiService.get(`${API_ENDPOINT_STAGING}/api/landing_page`).pipe(
        untilDestroyed(this)
      ).subscribe(u =>
      {
        if (u.on) {
          this.model = { ...u };
          this.visibleChange.next(true);
        }
      });
    }
  }

  ngOnDestroy(): void { }


  onClose(): void
  {
    this.visibleChange.emit(false);
  }
}
