import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';

@Directive({
  selector: '[appDigitValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: DigitValidatorDirective,
      multi: true
    }
  ]
})
export class DigitValidatorDirective implements Validator {
  validate(control: AbstractControl): { [key: string]: any } | null {
    var reg = /^\d+$/;
    let isValid = reg.test(control.value);

    if (control.value && !isValid) {
      return {
        digits: {
          valid: false
        }
      };
    } else {
      return null;
    }
  }
}
