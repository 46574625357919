import { Component, OnDestroy, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';
import { PomotionService } from 'src/app/core/services/http';
import { Promotion } from '../../../core/models';
import { resolveUrl } from '../../../shared/helpers';
import { shareReplay } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-promotion',
  templateUrl: './promotion.component.html',
  styleUrls: ['./promotion.component.scss']
})
export class PromotionComponent implements OnInit, OnDestroy
{
  constructor(private $pomotionService: PomotionService
    ,@Inject(PLATFORM_ID) private platformId: Object) { }

  slideConfig = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000
  };
  currentIndex = 0;
  promotionList$: Observable<Promotion[]>;


  ngOnInit(): void
  {
    if (isPlatformBrowser(this.platformId)) {
      this.promotionList$ = this.$pomotionService.list().pipe(
        untilDestroyed(this),
        shareReplay(1)
      );

      this.promotionList$.subscribe(r =>
      {
        if (r.length) {
          this.slideConfig.autoplaySpeed = (r[0].appearTime || 2) * 1000;
        }
      });
    }
  }

  ngOnDestroy(): void { }


  beforeChange($event: any): void
  {
    this.currentIndex = $event.nextSlide;
  }

  getLeftImgStyle(imgUrl: string): string
  {
    imgUrl = resolveUrl(imgUrl);

    return `url(${imgUrl})`;
  }
}
