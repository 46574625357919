import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import {
  map,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  filter
} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  constructor(private apiService: ApiService) {}

  search(search: string = ''): Observable<any[]> {
    const params = {
      search: search
    };

    return this.apiService
      .get(
        'https://www.majorplatinumcineplex.la/api/search',
        new HttpParams({
          fromObject: params
        })
      )
      .pipe(
        map(data => {
          ////console.log(data);
          return data;
        })
      );
  }
}
