import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild ,Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { map, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { GenericPage, NewsCategory, NewsInfo } from '../../../core/models';
import { NewsService,MetaService } from '../../../core/services/http';
import { PaginatorComponent } from '../../../shared/components';
import { isPlatformBrowser } from '@angular/common';

@Component({
   selector: 'app-news-list',
   templateUrl: './news-list.component.html',
   styleUrls: ['./news-list.component.scss']
})
export class NewsListComponent implements OnInit, AfterViewInit, OnDestroy
{
   @ViewChild(PaginatorComponent, { static: false }) $paginator: PaginatorComponent;

   constructor(
      private $uri: ActivatedRoute,
      private $router: Router,
      private $translate: TranslateService,
      private $newsService: NewsService,
      public metaService: MetaService,
      @Inject(PLATFORM_ID) private platformId: Object
   )
   {
      this.categories = [this.emptyCategory];
   }

   readonly pageSize = 9;
   readonly emptyCategory = { id: 0, name: 'all news', nameKH: 'all news' };

   private _activeCategory$ = new ReplaySubject<number>(1);
   private _categories$: Observable<NewsCategory[]>;

   categories: NewsCategory[];
   models$: Observable<GenericPage<NewsInfo>>;


   ngOnInit(): void
   {

    if (isPlatformBrowser(this.platformId)) {
        this._categories$ = this.$newsService.listCategory().pipe(
          map(v => [this.emptyCategory, ...v]),
          shareReplay(1)
        );

        //this.metaService.addTags('NEWS & ACTIVITIES');
        this.$translate.onLangChange.asObservable().pipe(
          untilDestroyed(this),
          startWith({}),
          switchMap(() =>
          {
              const field = (this.$translate.currentLang === 'kh' ? 'nameKH' : 'name');

              return this._categories$.pipe(
                untilDestroyed(this),
                map(v => v.map(x =>
                {
                    return { ...x, name: x[field] };
                }))
              );
          })
        ).subscribe(u => this.categories = [...u]);

        this.models$ = this.$uri.queryParamMap.pipe(
          switchMap(u =>
          {
              const category = parseInt(u.get('category') || '0');
              const page = parseInt(u.get('page') || '0');
              const categoryId = (this.categories.length ? this.categories[category].id : 0);

              return this.$newsService.listByCategory(categoryId).pipe(
                map(r =>
                {
                    if (r.totalCount) {
                      const models = [...r.items];
                      models.splice(1, 0, null);

                      const skip = (page * this.pageSize);
                      const top = Math.min(skip + this.pageSize, models.length);

                      return {
                          items: models.slice(skip, top),
                          totalCount: r.totalCount + 1
                      };
                    }

                    return r;
                })
              );
          }),
          shareReplay(1)
        );
    }
   }

   ngAfterViewInit(): void
   {
      const pageChange$ = this.$paginator.pageChange.asObservable();
      const activeCategory$ = this._activeCategory$.asObservable();

      combineLatest(activeCategory$, pageChange$).pipe(
         untilDestroyed(this)
      ).subscribe(([categoryIdx, pageIdx]) =>
      {
         const params = this.$uri.snapshot.queryParamMap;
         const category = parseInt(params.get('category') || '0');
         const page = parseInt(params.get('page') || '0');

         if (category !== categoryIdx || page !== pageIdx) {
            this.$router.navigate([], {
               relativeTo: this.$uri,
               queryParamsHandling: 'merge',
               queryParams: { category: categoryIdx, page: pageIdx }
            });
         }
      });

      this.$uri.queryParamMap.pipe(
         untilDestroyed(this)
      ).subscribe(u =>
      {
         const category = parseInt(u.get('category') || '0');
         const page = parseInt(u.get('page') || '0');

         this._activeCategory$.next(category);
         this.$paginator.pageChange.emit(page);
      });
   }

   ngOnDestroy(): void { }


   onCategoryChange(index: number): void
   {
      this._activeCategory$.next(index);

      if (this.$paginator.pageIndex) {
         this.$paginator.pageChange.emit(0);
      }
   }
}
