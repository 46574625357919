import { Pipe, PipeTransform } from '@angular/core';
import { isString } from 'util';

@Pipe({
   name: 'val'
})
export class ValPipe implements PipeTransform
{
   transform(value: any, defaultValue?: any): any
   {
      return (isString(value) && value.length ? value : defaultValue);
   }
}
