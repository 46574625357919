import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { FacebookService, InitParams, UIParams, UIResponse } from 'ngx-facebook';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class ShareService {
  constructor(
    private $meta: Meta,
    private $title: Title,
    private fb: FacebookService,
    private translate: TranslateService) {
    const initParams: InitParams = {
      appId: '240969287079039',
      xfbml: true,
      version: 'v6.0'
    };

    this.fb.init(initParams);
  }

  readonly facebook = ['og:type', 'og:url', 'og:title', 'og:description', 'og:image'];

  // tslint:disable-next-line:variable-name
  private _titles: { [key: string]: string } = {};
  // tslint:disable-next-line:variable-name
  private _metas: { [key: string]: any } = {};


  backup(type: 'facebook'): void {
    this._titles[type] = this.$title.getTitle();
    this._metas[type] = this._metas[type] || {};

    this.facebook.forEach(u => this._metas[type][u] = this.$meta.getTag(`name="${u}"`));
  }

  restore(type: 'facebook'): void {
    const title = (this._titles.hasOwnProperty(type) ? this._titles[type] : '');
    const meta = (this._metas.hasOwnProperty(type) ? this._metas[type] : {});

    this.commit(type, title, meta);
  }

  commit(type: 'facebook', title: string, meta: { [key: string]: string }): void {
    this.backup(type);

    this.$title.setTitle(title);
    this.facebook.forEach(u => {
      const name = u;
      const content = (meta.hasOwnProperty(u) ? meta[u] : '');

      this.$meta.updateTag({ name, content }, `name="${name}"`);
    });
  }

  share(url: string) {
    ////console.log(url);
    ////console.log(this.translate.currentLang);
    const params: UIParams = {
      href: url,
      method: 'share',
      /*action_properties: JSON.stringify({
        object : {
          'og:url': 'url', // your url to share
          'og:title': 'title',
          'og:site_name':'site_name',
          'og:description':'description',
          'og:image': 'image Url',//
          'og:image:width':'250',//size of image in pixel
          'og:image:height':'257'
        }
      })*/
    };

    this.fb.ui(params)
      .then((res: UIResponse) => console.log(res))
      .catch((e: any) => console.log(e));
  }
}
