import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BookingService {
  constructor(private apiService: ApiService) {}

  reserve(request: Booking): Observable<any> {
    return this.apiService
      .post('https://www.majorplatinumcineplex.la/api/booking', request)
      .pipe(map(data => data));
  }

  pushback(request: PushBack): Observable<any> {
    return this.apiService
      .post('https://www.majorplatinumcineplex.la/api/pushback', request)
      .pipe(map(data => data));
  }
}

interface PushBack {
  tranId: string;
  otp: string;
}
interface Booking {
  cinema_id: string;
  session_id: string;
  booking_mode: number;
  language: string;
  customer_tel: string;
  customer_email: string;
  seats: SeatBooking[];
}

interface SeatBooking {
  ticket_type_code: string;
  area_category_code: string;
  area_number: number;
  row_index: number;
  column_index: number;
  alphabet: string;
  number: number;
}
