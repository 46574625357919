import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { CinemaInfo, MoviePage2 } from '../../../core/models';
import { CinemaService } from '../../../core/services/http';
import { TranslateService } from '@ngx-translate/core';
import { MetaService } from '../../../core/services/http';
import { ShareService } from 'src/app/shared/services';

@Component({
   selector: 'app-cinema-info',
   templateUrl: './cinema-info.component.html',
   styleUrls: ['./cinema-info.component.scss']
})
export class CinemaInfoComponent implements OnInit, OnDestroy
{
   constructor(
      private $uri: ActivatedRoute,
      private $cinemaService: CinemaService,
      public metaService: MetaService,
      public shareService: ShareService,
      private translate: TranslateService
   )
   {
      this.menus = [...this.defaultMenus];
      this.categories = [...this.defaultCategories];
   }

   movieModel: object;
   readonly defaultMenus = [
      { field: 'information', name: 'cinema information' },
      { field: 'otherService', name: 'other service' }
   ];
   readonly defaultCategories = [
      { name: 'now showing' },
      { name: 'coming soon' }
   ];

   private _activeMenu$ = new Subject<number>();
   private _activeMovie$ = new Subject<number>();

   menus: { field: string, name: string }[];
   categories: { name: string }[];

   model$: Observable<CinemaInfo>;
   movies$: Observable<MoviePage2>;
   activeMenu$: Observable<number>;
   activeMovie$: Observable<number>;
   informationTab:boolean = true;

   ngOnInit(): void
   {
      this.activeMenu$ = this._activeMenu$.asObservable();
      this.activeMovie$ = this._activeMovie$.asObservable();

      this.model$ = this.$uri.paramMap.pipe(
         switchMap(u => this.$cinemaService.read(u.get('id'))),
         shareReplay(1)
      );
      //this.share();
      this.movies$ = this.activeMovie$.pipe(
         switchMap(u => combineLatest(of(u), this.model$)),
         map(([index, model]) =>
         {
            let movies = [];

            if (index === 0) {
               movies = model.nowShowing;
               for(var i =0;i<movies.length;i++){
                movies[i].hide_btn = 0;
               }
            } else if (index === 1) {
               movies = model.commingSoon;
               for(var i =0;i<movies.length;i++){
                movies[i].hide_btn = 1;
               }
            }
            ////console.log(movies);

            return movies.length ? { list1: movies, list2: [] } : null;
         })
      );


      // Check other service status
      this.model$.subscribe(u =>
      {
         if (!u.otherServiceStatus) {
            this.menus = this.defaultMenus.filter(v => v.field !== 'otherService');
         }
      });
   }

   ngOnDestroy(): void { }


   onMenuChange(index: number): void
   {
      this.informationTab = index == 0 ? true:false;
      ////console.log('informationTab : '+this.informationTab);
      this._activeMenu$.next(index);
   }

   onMovieChange(index: number): void
   {
      this._activeMovie$.next(index);
   }

   share() {
    this.model$.subscribe(data => {
      /*//console.log('cinema');
      //console.log(data);
      if (this.translate.currentLang === 'en') {
        this.movieModel = {
          title: data.name,
          image: data.highlightBanner,
          description: data.address_details
        };
      } else {
        this.movieModel = {
          title: data.nameAlt,
          image: data.highlightBannerMobile,
          description: data.address_details2
        };
      }
      this.metaService.addOGTags(this.movieModel, window.location.href);*/
      this.shareService.share('https://www.majorplatinumcineplex.la/share_cinema/'+data.id);
    });
  }
}
