import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home.component';
import { CinemaInfoComponent, CinemaListComponent, ContactUsComponent, MovieInfoComponent, NewsInfoComponent, NewsListComponent, PromotionInfoComponent, PromotionListComponent,UnderconstuctionComponent } from './pages';

const routes: Routes = [
   {
      path: '',
      redirectTo: 'home',
      pathMatch: 'full'
   },

   { path: 'home', component: HomeComponent },
   { path: 'underconstruction', component: UnderconstuctionComponent },

   { path: 'movies/:id', component: MovieInfoComponent },

   {
      path: 'cinema',
      children: [
         { path: ':id', component: CinemaInfoComponent },
         { path: '', component: CinemaListComponent }
      ]
   },
   {
      path: 'promotions',
      children: [
         { path: ':id', component: PromotionInfoComponent },
         { path: '', component: PromotionListComponent }
      ]
   },
   {
      path: 'news-and-activity',
      children: [
         { path: ':id', component: NewsInfoComponent },
         { path: '', component: NewsListComponent }
      ]
   },
   { path: 'contact-us', component: ContactUsComponent },


   {
    path: 'showtime',
    loadChildren: () =>
      import('../showtime/showtime.module').then(m => m.ShowtimeModule)
  },
  { path: '**',
   redirectTo: 'home', }
];

@NgModule({
   imports: [RouterModule.forChild(routes)],
   exports: [RouterModule]
})
export class HomeRoutingModule { }
