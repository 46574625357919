import { formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit,Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';
import { shareReplay, switchMap } from 'rxjs/operators';
import { PromotionInfo2 } from '../../../core/models';
import { Promotion2Service, MetaService } from '../../../core/services/http';
import { ShareService } from 'src/app/shared/services';
import { isPlatformBrowser } from '@angular/common';

@Component({
   selector: 'app-promotion-info',
   templateUrl: './promotion-info.component.html',
   styleUrls: ['./promotion-info.component.scss']
})
export class PromotionInfoComponent implements OnInit, OnDestroy {
   promotionModel: object;

   constructor(
      private $uri: ActivatedRoute,
      private $translate: TranslateService,
      private $promotion2Service: Promotion2Service,
      public metaService: MetaService,
      public shareService: ShareService,
      @Inject(PLATFORM_ID) private platformId: Object
   ) { }

   model$: Observable<PromotionInfo2>;


   ngOnInit(): void {

    if (isPlatformBrowser(this.platformId)) {
        this.model$ = this.$uri.paramMap.pipe(
          untilDestroyed(this),
          switchMap(u => this.$promotion2Service.read(u.get('id'))),
          shareReplay(1)
        );

        //this.metaService.addTags('PROMOTIONS');
        this.model$.subscribe(data => {
          // //console.log(data);
          this.promotionModel = {
              title: data.title,
              image: data.promotionImage,
              description: data.description
          };
          this.metaService.addOGTags(this.promotionModel, window.location.href);
        });
    }
   }

   ngOnDestroy(): void { }


   getPeriod(start: string, end: string): string {
      // const locale = this.$translate.currentLang;
      const locale = 'en-US';
      let periodText = formatDate(end, 'd MMMM y', locale);

      if (start) {
         const startDate = new Date(start);
         const endDate = new Date(end);

         if (startDate.getMonth() === endDate.getMonth()) {
            periodText = `${formatDate(start, 'd', locale)}-${periodText}`;
         } else {
            periodText = `${formatDate(start, 'd MMMM y', locale)}-${periodText}`;
         }
      }

      return periodText;
   }

   share() {
      this.model$.subscribe(data => {
        ////console.log(data);
         /*if (this.$translate.currentLang === 'en') {
            this.promotionModel = {
               title: data.title,
               image: data.promotionImage,
               description: data.description
            };
         } else {
            this.promotionModel = {
               title: data.titleKh,
               image: data.promotionImage,
               description: data.descriptionKh
            };
         }
         this.metaService.addOGTags(this.promotionModel, window.location.href);*/
         this.shareService.share('https://www.majorplatinumcineplex.la/share_promotion/'+data.id);
      });
   }
}

