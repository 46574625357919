import { Component, OnInit } from '@angular/core';
import { AdsService } from 'src/app/core/services/http';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-ads-area',
  templateUrl: './ads-area.component.html',
  styleUrls: ['./ads-area.component.scss']
})
export class AdsAreaComponent implements OnInit
{
  adsList$: Observable<any[]>;

  constructor(private adsService: AdsService) { }

  ngOnInit()
  {
    this.adsList$ = this.adsService.getAll();
  }
}
