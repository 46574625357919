import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild,Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { map, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { GenericPage, PromotionCategory, PromotionInfo2 } from '../../../core/models';
import { Promotion2Service, MetaService } from '../../../core/services/http';
import { PaginatorComponent } from '../../../shared/components';
import { ShareService } from 'src/app/shared/services';
import { isPlatformBrowser } from '@angular/common';
import { Renderer } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
   selector: 'app-promotion-list',
   templateUrl: './promotion-list.component.html',
   styleUrls: ['./promotion-list.component.scss']
})
export class PromotionListComponent implements OnInit, AfterViewInit, OnDestroy {
   @ViewChild(PaginatorComponent, { static: false }) $paginator: PaginatorComponent;
   promotionModel: object;

   constructor(
      private $uri: ActivatedRoute,
      private $router: Router,
      private $translate: TranslateService,
      private $promotion2Service: Promotion2Service,
      public metaService: MetaService,
      public shareService: ShareService,
      @Inject(DOCUMENT) private document: any,
      private renderer: Renderer,
      @Inject(PLATFORM_ID) private platformId: Object
   ) { }

   readonly pageSize = 6;

   private _activeCategory$ = new ReplaySubject<number>(1);
   private _categories$: Observable<PromotionCategory[]>;

   categories: PromotionCategory[] = [];
   models$: Observable<GenericPage<PromotionInfo2>>;
   highlight$: Observable<PromotionInfo2>;


   ngOnInit(): void {
      if (isPlatformBrowser(this.platformId)) {
        this._categories$ = this.$promotion2Service.listCategory().pipe(
          shareReplay(1)
        );

        //this.metaService.addTags('HOME');
        this.$translate.onLangChange.asObservable().pipe(
          untilDestroyed(this),
          startWith({}),
          switchMap(() => {
              const field = (this.$translate.currentLang === 'kh' ? 'nameKH' : 'name');

              return this._categories$.pipe(
                untilDestroyed(this),
                map(v => v.map(x => {
                    return { ...x, name: x[field] };
                }))
              );
          })
        ).subscribe(u => this.categories = [...u]);

        this._categories$.pipe(
          untilDestroyed(this)
        ).subscribe(u => {
          this.models$ = this.$uri.queryParamMap.pipe(
              switchMap(u => {

                const category = parseInt(u.get('category') || '0');
                const page = parseInt(u.get('page') || '0');
                const categoryId = (this.categories.length ? this.categories[category].id : 0);

                return this.$promotion2Service.listByCategory(categoryId).pipe(
                    map(r => {
                      if (r.totalCount) {
                          const models = r.items;
                          const skip = (page * this.pageSize);
                          const top = Math.min(skip + this.pageSize, models.length);

                          return {
                            items: models.slice(skip, top),
                            totalCount: r.totalCount
                          };
                      }

                      return r;
                    })
                );
              }),
              shareReplay(1)
          );
        });

        this.highlight$ = this.$promotion2Service.readHighlight().pipe(
          shareReplay(1)
        );

        /*this.highlight$.subscribe(data => {
          // //console.log(data);
          this.promotionModel = {
              title: data.title,
              image: data.promotionImage,
              description: data.description
          };
          this.metaService.addOGTags(this.promotionModel, window.location.href);


        });*/
      }
   }

   ngAfterViewInit(): void {
      const pageChange$ = this.$paginator.pageChange.asObservable();
      const activeCategory$ = this._activeCategory$.asObservable();

      combineLatest(activeCategory$, pageChange$).pipe(
         untilDestroyed(this)
      ).subscribe(([categoryIdx, pageIdx]) => {
         const params = this.$uri.snapshot.queryParamMap;
         const category = parseInt(params.get('category') || '0');
         const page = parseInt(params.get('page') || '0');

         if (category !== categoryIdx || page !== pageIdx) {
            this.$router.navigate([], {
               relativeTo: this.$uri,
               queryParamsHandling: 'merge',
               queryParams: { category: categoryIdx, page: pageIdx }
            });
         }
      });

      this.$uri.queryParamMap.pipe(
         untilDestroyed(this)
      ).subscribe(u => {
         const category = parseInt(u.get('category') || '0');
         const page = parseInt(u.get('page') || '0');

         this._activeCategory$.next(category);
         this.$paginator.pageChange.emit(page);
      });
   }

   ngOnDestroy(): void { }


   onCategoryChange(index: number): void {
      this._activeCategory$.next(index);

      if (this.$paginator.pageIndex) {
         this.$paginator.pageChange.emit(0);
      }
   }

   share() {
      this.shareService.share('https://www.majorplatinumcineplex.la/share_promotion');
   }
}
