import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';
import { shareReplay, switchMap } from 'rxjs/operators';
import { NewsInfo } from '../../../core/models';
import { NewsService, MetaService } from '../../../core/services/http';
import { ShareService } from 'src/app/shared/services';
import { TranslateService } from '@ngx-translate/core';

@Component({
   selector: 'app-news-info',
   templateUrl: './news-info.component.html',
   styleUrls: ['./news-info.component.scss']
})
export class NewsInfoComponent implements OnInit, OnDestroy {
   newsModel: object;

   constructor(
      private $uri: ActivatedRoute,
      private $newsService: NewsService,
      public metaService: MetaService,
      public shareService: ShareService,
      public translate: TranslateService
   ) { }

   model$: Observable<NewsInfo>;


   ngOnInit(): void {
      this.model$ = this.$uri.paramMap.pipe(
         untilDestroyed(this),
         switchMap(u => this.$newsService.read(u.get('id'))),
         shareReplay(1)
      );

      this.metaService.addTags('NEWS & ACTIVITIES');
      this.model$.subscribe(data => {
         // //console.log(data);
         this.newsModel = {
            title: data.headline,
            image: data.highlightImage,
            description: ''
         };
         this.metaService.addOGTags(this.newsModel, window.location.href);
      });
   }

   ngOnDestroy(): void { }

   share() {
      this.model$.subscribe(data => {
         // //console.log(data);
         if (this.translate.currentLang === 'en') {
            this.newsModel = {
               title: data.headline,
               image: data.highlightImage,
               description: ''
            };
         } else {
            this.newsModel = {
               title: data.headlineKH,
               image: data.highlightImage,
               description: ''
            };
         }
         this.metaService.addOGTags(this.newsModel, window.location.href);
         this.shareService.share('https://www.majorplatinumcineplex.la/share_news/'+data.id);
      });
   }
}

