import { isArray } from 'util';

export const TrueValues = [true, 1, 'true'];
export const FalseValues = [false, 0, 'false'];
export const BooleanValues = [...TrueValues, ...FalseValues];

export function isBoolean(value: any): boolean
{
   return BooleanValues.indexOf(value) > -1;
}

export function isTrue(value: any): boolean
{
   return TrueValues.indexOf(value) > -1;
}

export function isFalse(value: any): boolean
{
   return FalseValues.indexOf(value) > -1;
}

export function toBoolean(value: any, excepts: any = ['', null, undefined]): boolean
{
   if (isArray(excepts) && excepts.indexOf(value) > -1) { return value; }

   if (isBoolean(value)) { return !isFalse(value); }

   return false;
}