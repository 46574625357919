import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class ApiService {
  constructor(private http: HttpClient) {}

  private _resolveUri(path: string) {
    return /^https?/.test(path) ? path : `${environment.apiEndpoint}${path}`;
  }

  private formatErrors(error: any) {
    return throwError(error.error);
  }

  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http
      .get(this._resolveUri(path), { params })
      .pipe(catchError(this.formatErrors));
  }

  put(path: string, body: Object = {}): Observable<any> {
    return this.http
      .put(this._resolveUri(path), JSON.stringify(body))
      .pipe(catchError(this.formatErrors));
  }

  post(path: string, body: FormData | Object = {}): Observable<any> {
    const payload = body instanceof FormData ? body : JSON.stringify(body);

    const httpOptions = {
      headers: new HttpHeaders()
    };

    if (!(body instanceof FormData)) {
      httpOptions.headers = httpOptions.headers.set(
        'Content-Type',
        'application/json'
      );
    }

    return this.http
      .post(this._resolveUri(path), payload, httpOptions)
      .pipe(catchError(this.formatErrors));
  }

  delete(path): Observable<any> {
    return this.http
      .delete(this._resolveUri(path))
      .pipe(catchError(this.formatErrors));
  }
}
