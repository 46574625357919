import { Injectable,Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { API_ENDPOINT_STAGING } from '../../../shared/helpers';
import { ApiService } from '../api.service';
import { HttpResponse } from '@angular/common/http';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GtmService {
  constructor(private apiService: ApiService
    ,@Inject(DOCUMENT) private $document: Document) { }

  getAll() {
     return this.apiService.get(`${API_ENDPOINT_STAGING}/api/get_gtm`);
    //return this.apiService.get("http://localhost:8080/web-api/get_gtm");
    // return this.mockApi().pipe(map(e=>e.body));
  }

  addGtmScripts() {
    //document.head.insertBefore(headTag, document.head.firstChild);
    this.getAll().subscribe(res => res.forEach(data => {
        if(data.type == 0) {
          this.$document.getElementsByTagName('head')[0].innerHTML += data.body;
        }
        else {
          var body = data.body;
          var temp = data.body.split('<noscript>');
          var temp2 = temp[1].split('</noscript>');
          var parser = new DOMParser();
          var DOM = parser.parseFromString(body, 'text/html');
          this.$document.getElementsByTagName('body')[0].appendChild(this.$document.createRange().createContextualFragment(temp[0]+'<noscript id="google"></noscript>'+temp2[1]));
          this.$document.getElementById('google').appendChild(DOM.body.childNodes[0]);
        }
    }));
  }
}
