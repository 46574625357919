import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { ApiService } from '../api.service';
import { HttpResponse } from '@angular/common/http';
import { map, shareReplay } from 'rxjs/operators';
import { API_ENDPOINT_STAGING } from '../../../shared/helpers';

@Injectable({
  providedIn: 'root'
})
export class MetaService {
  constructor(private apiService: ApiService, private meta: Meta, private $translate: TranslateService) { }

  data$: Observable<any>;

  getAll(page) {
    this.data$ = this.apiService.get(`${API_ENDPOINT_STAGING}/api/get_meta/`+page);
    //this.data$ = this.apiService.get(`http://localhost:8080/web-api/get_meta`)
    // this.data$ = of(new HttpResponse({ status: 200, body: { title_en: "bg-title", keyword_en: "bg-keyword", description_en: "bg-description" } })).pipe(map(data => data.body));
    this.data$.pipe(shareReplay(1));
    return this.data$;

  }

  getAllMovie(page) {
    this.data$ = this.apiService.get(`${API_ENDPOINT_STAGING}/api/get_meta_movie/`+page);
    //this.data$ = this.apiService.get(`http://localhost:8080/web-api/get_meta`)
    // this.data$ = of(new HttpResponse({ status: 200, body: { title_en: "bg-title", keyword_en: "bg-keyword", description_en: "bg-description" } })).pipe(map(data => data.body));
    this.data$.pipe(shareReplay(1));
    return this.data$;

  }


  getMetaPromotion(page) {
    this.data$ = this.apiService.get(`${API_ENDPOINT_STAGING}/api/get_meta_promotion/`+page);
    //this.data$ = this.apiService.get(`http://localhost:8080/web-api/get_meta`)
    // this.data$ = of(new HttpResponse({ status: 200, body: { title_en: "bg-title", keyword_en: "bg-keyword", description_en: "bg-description" } })).pipe(map(data => data.body));
    this.data$.pipe(shareReplay(1));
    return this.data$;

  }

  getMetaCinema(page) {
    this.data$ = this.apiService.get(`${API_ENDPOINT_STAGING}/api/get_meta_cinema/`+page);
    //this.data$ = this.apiService.get(`http://localhost:8080/web-api/get_meta`)
    // this.data$ = of(new HttpResponse({ status: 200, body: { title_en: "bg-title", keyword_en: "bg-keyword", description_en: "bg-description" } })).pipe(map(data => data.body));
    this.data$.pipe(shareReplay(1));
    return this.data$;
  }

  getMetaNews(page) {
    this.data$ = this.apiService.get(`${API_ENDPOINT_STAGING}/api/get_meta_news/`+page);
    //this.data$ = this.apiService.get(`http://localhost:8080/web-api/get_meta`)
    // this.data$ = of(new HttpResponse({ status: 200, body: { title_en: "bg-title", keyword_en: "bg-keyword", description_en: "bg-description" } })).pipe(map(data => data.body));
    this.data$.pipe(shareReplay(1));
    return this.data$;
  }


  addTagsPromotion(page) {
    const field = (this.$translate.currentLang === 'kh' ? '_kh' : '');
    this.getMetaPromotion(page).subscribe(res => {
      this.meta.updateTag({ name: 'title', content: res[`seo_title${field}`] });
      this.meta.updateTag({ name: 'keyword', content: res[`seo_keyword${field}`] });
      this.meta.updateTag({ name: 'description', content: res[`seo_description${field}`] });

    });
    this.$translate.onLangChange
      .subscribe(u => {
        const field = (this.$translate.currentLang === 'kh' ? '_kh' : '');
        this.getMetaPromotion(page).subscribe(res => {
          this.meta.updateTag({ name: 'title', content: res[`seo_title${field}`] });
          this.meta.updateTag({ name: 'keyword', content: res[`seo_keyword${field}`] });
          this.meta.updateTag({ name: 'description', content: res[`seo_description${field}`] });

        });
      });
  }

  addTagsCinema(page) {
    const field = (this.$translate.currentLang === 'kh' ? '_kh' : '');
    this.getMetaCinema(page).subscribe(res => {
      this.meta.updateTag({ name: 'title', content: res[`seo_title${field}`] });
      this.meta.updateTag({ name: 'keyword', content: res[`seo_keyword${field}`] });
      this.meta.updateTag({ name: 'description', content: res[`seo_description${field}`] });

    });
    this.$translate.onLangChange
      .subscribe(u => {
        const field = (this.$translate.currentLang === 'kh' ? '_kh' : '');
        this.getMetaCinema(page).subscribe(res => {
          this.meta.updateTag({ name: 'title', content: res[`seo_title${field}`] });
          this.meta.updateTag({ name: 'keyword', content: res[`seo_keyword${field}`] });
          this.meta.updateTag({ name: 'description', content: res[`seo_description${field}`] });

        });
      });
  }

  addTagsNews(page) {
    const field = (this.$translate.currentLang === 'kh' ? '_kh' : '');
    this.getMetaNews(page).subscribe(res => {
      this.meta.updateTag({ name: 'title', content: res[`seo_title${field}`] });
      this.meta.updateTag({ name: 'keyword', content: res[`seo_keyword${field}`] });
      this.meta.updateTag({ name: 'description', content: res[`seo_description${field}`] });

    });
    this.$translate.onLangChange
      .subscribe(u => {
        const field = (this.$translate.currentLang === 'kh' ? '_kh' : '');
        this.getMetaNews(page).subscribe(res => {
          this.meta.updateTag({ name: 'title', content: res[`seo_title${field}`] });
          this.meta.updateTag({ name: 'keyword', content: res[`seo_keyword${field}`] });
          this.meta.updateTag({ name: 'description', content: res[`seo_description${field}`] });

        });
      });
  }

  addTagsMovie(page) {
    const field = (this.$translate.currentLang === 'kh' ? '_kh' : '');
    const field_title = (this.$translate.currentLang === 'kh' ? '_kn' : '');
    this.getAllMovie(page).subscribe(res => {
      this.meta.updateTag({ name: 'title', content: res[`seo_title${field_title}`] });
      this.meta.updateTag({ name: 'keyword', content: res[`seo_keyword${field}`] });
      this.meta.updateTag({ name: 'description', content: res[`seo_description${field}`] });

    });
    this.$translate.onLangChange
      .subscribe(u => {
        const field = (this.$translate.currentLang === 'kh' ? '_kh' : '');
        this.getAllMovie(page).subscribe(res => {
          this.meta.updateTag({ name: 'title', content: res[`seo_title${field}`] });
          this.meta.updateTag({ name: 'keyword', content: res[`seo_keyword${field}`] });
          this.meta.updateTag({ name: 'description', content: res[`seo_description${field}`] });

        });
      });
  }

  addTags(page) {
    ////console.log('PAGE :'+page);
    const field = (this.$translate.currentLang === 'kh' ? '_kh' : '_en');
    this.getAll(page).subscribe(res => {
      this.meta.updateTag({ name: 'title', content: res[`title${field}`] });
      this.meta.updateTag({ name: 'keyword', content: res[`keyword${field}`] });
      this.meta.updateTag({ name: 'description', content: res[`description${field}`] });

    });
    this.$translate.onLangChange
      .subscribe(u => {
        const field = (this.$translate.currentLang === 'kh' ? '_kh' : '_en');
        this.getAll(page).subscribe(res => {
          this.meta.updateTag({ name: 'title', content: res[`title${field}`] });
          this.meta.updateTag({ name: 'keyword', content: res[`keyword${field}`] });
          this.meta.updateTag({ name: 'description', content: res[`description${field}`] });

        });
      });
  }

  addOGTags(meta, url) {
    this.meta.updateTag({ property: 'og:title', content: meta.title });
    this.meta.updateTag({ property: 'og:url', content: url });
    this.meta.updateTag({ property: 'og:image', content: meta.image });
    this.meta.updateTag({ property: 'og:description', content: meta.description }); // Not description from cms.
  }

}

