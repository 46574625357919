import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { SocialService, MetaService, GtmService } from '../core/services/http';
import { map } from 'rxjs/operators';
import { HttpClient  } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit
{
  appDetail = {
    socials: [
      {
        name: 'facebook',
        icon: 'facebook.svg',
        url: 'https://www.facebook.com/majorcineplexcambodia'
      },
      {
        name: 'line',
        icon: 'line.svg',
        url: '#'
      },
      {
        name: 'what app',
        icon: 'what-app.svg',
        url: '#'
      },
      {
        name: 'instagram',
        icon: 'instagram.svg',
        url: 'https://instagram.com/majorkh'
      }
    ],

    appStore: [
      {
        name: 'Play Store',
        icon: 'play-store.svg',
        url: '#'
      },
      {
        name: 'Apple Store',
        icon: 'apple-store.svg',
        url: '#'
      }
    ],

    tell: '023 901111'
  };

  constructor(private http:HttpClient,private socialService: SocialService, private metaService: MetaService, private gtmService: GtmService,@Inject(PLATFORM_ID) private platformId: Object) {
    /*this.getIPAddress().subscribe((res:any)=>{
      //console.log(res.ip);
      window.location.href = '/underconstruction';
    });*/
  }

  public getIPAddress()
  {
    return this.http.get("http://api.ipify.org/?format=json");
  }

  ngOnInit()
  {
    //this.gtmService.a
    if (isPlatformBrowser(this.platformId)) {
      this.socialService.getAll().pipe(
        map(x =>
        {
          this.appDetail.socials = x.filter(y => y.disabled === 0);
        })
      ).subscribe();
    }

    //this.metaService.addTags('HOME');
  }
}
