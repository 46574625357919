import { Injectable } from '@angular/core';
import { API_ENDPOINT_STAGING } from '../../../shared/helpers';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class SocialService
{
  constructor(private apiService: ApiService) { }

  getAll()
  {
    return this.apiService.get(`${API_ENDPOINT_STAGING}/api/social/config`);
  }
}
