import { Component, HostBinding, Input } from '@angular/core';
import { HighlightBg } from './highlight_bg.model';
import { HighlightConfig } from './highlight_config.model';
import { HighlightTag } from './highlight_tag.model';

@Component({
   selector: 'app-highlight',
   templateUrl: './highlight.component.html',
   styleUrls: ['./highlight.component.scss']
})
export class HighlightComponent
{
   @HostBinding('class.app-highlight') hostCss = true;

   @Input('config') config?: HighlightConfig;
   @Input('tag') tag?: HighlightTag;
   @Input('bg') bg?: HighlightBg;
}
