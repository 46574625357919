import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
   selector: 'app-movie-poster2-header',
   templateUrl: './movie-poster2-header.component.html',
   styleUrls: ['./movie-poster2-header.component.scss']
})
export class MoviePoster2HeaderComponent implements OnChanges
{
   @Input('categories') categories: { [key: string]: any }[];
   @Input('startIndex') startIndex: number;
   @Output() change = new EventEmitter<number>(true);


   ngOnChanges({ startIndex }: SimpleChanges): void
   {
      if (startIndex) {
         this.onChange(startIndex.currentValue);
      }
   }


   onChange(index: number): void
   {
      this.change.emit(index);
   }
}
