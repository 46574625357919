import { Component, Input } from '@angular/core';
import { MovieDetail2 } from '../../../core/models';

@Component({
  selector: 'app-movie-detail2',
  templateUrl: './movie-detail2.component.html',
  styleUrls: [
    '../movie-detail/movie-detail.component.scss',
    './movie-detail2.component.scss'
  ]
})
export class MovieDetail2Component
{
  @Input() movie: MovieDetail2;
}
