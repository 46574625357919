import { from } from 'rxjs';

export * from './ads.service';
export * from './banner.service';
export * from './booking.service';
export * from './cinema.service';
export * from './contact_us.service';
export * from './movie.service';
export * from './movies.service';
export * from './news.service';
export * from './pomotion.service';
export * from './promotion2.service';
export * from './search.service';
export * from './show-time.service';
export * from './social.service';
export * from './meta.service'
export * from './gtm.service';
