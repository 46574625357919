import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_ENDPOINT_STAGING } from '../../../shared/helpers';
import { MovieAds, MovieInfo2 } from '../../models';
import { ApiService } from '../api.service';

@Injectable({ providedIn: 'root' })
export class MovieService
{
   constructor(private $apiService: ApiService) { }

   get basePath() { return `${API_ENDPOINT_STAGING}/api`; }


   read(id: number | string): Observable<MovieInfo2>
   {
      return this.$apiService.get(`${this.basePath}/movie-detail`, { movie: id } as any);
   }

   listAds(): Observable<MovieAds[]>
   {
      return this.$apiService.get(`${this.basePath}/ads_movie_detail`);
   }
}
