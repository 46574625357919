import { Injector, Pipe, PipeTransform, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslateToPipe } from './translate_to.pipe';

@Pipe({ name: 'translateToKH', pure: false })
export class TranslateToKHPipe extends TranslateToPipe implements PipeTransform
{
   constructor(
      public $changeRef: ChangeDetectorRef,
      public $translate: TranslateService
   )
   {
      super($changeRef, $translate);
   }

   transform(from: string, to?: string): string | null
   {
      return super.transform(from, to, 'kh');
   }
}
