import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_ENDPOINT_STAGING } from '../../../shared/helpers';
import { Promotion } from '../../models';
import { ApiService } from '../api.service';

@Injectable({ providedIn: 'root' })
export class PomotionService
{
  constructor(private $apiService: ApiService) { }


  list(): Observable<Promotion[]>
  {
    return this.$apiService.get(`${API_ENDPOINT_STAGING}/api/promotion-slide`);
  }
}
