import { Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TagConfig } from './tag_config.model';

@Component({
   selector: 'app-tag',
   templateUrl: './tag.component.html',
   styleUrls: ['./tag.component.scss']
})
export class TagComponent implements OnChanges
{
   @HostBinding('class.app-tag') hostCss = true;
   @HostBinding('style.background-color') backgroundColor: string;
   @HostBinding('style.color') color: string;
   @HostBinding('style.width') width: string;
   @HostBinding('style.height') height: string;

   @Input('label') label: string;
   @Input('config') config?: TagConfig;

   constructor()
   {
      this._updateStyles(this.defaultConfig);
   }

   readonly defaultConfig: TagConfig = {
      color: 'inherit',
      backgroundColor: '#CDA566',
      width: 'auto',
      height: 'auto'
   };


   private _updateStyles(config: TagConfig)
   {
      this.backgroundColor = config.backgroundColor;
      this.color = config.color;
      this.width = config.width;
      this.height = config.height;
   }


   ngOnChanges({ config }: SimpleChanges): void
   {
      if (config) {
         this.config = { ...this.defaultConfig, ...config.currentValue };
         this._updateStyles(this.config);
      }
   }
}
