import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { DOCUMENT } from '@angular/common';


if (environment.production) {
  enableProdMode();

  // if (window) {
  //   window.//console.log = function() {};
  //   window.console.table = function() {};
  // }
}

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));
});
