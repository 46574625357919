import { Component, Input, OnDestroy, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { BannerService } from 'src/app/core/services/http';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit, OnDestroy
{
  @Input() appDetail: any;

  bannerList$: Observable<any[]>;

  slideConfig = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000
  };

  currentIndex: number = 0;

  constructor(private bannerService: BannerService, private meta: Meta
    ,@Inject(PLATFORM_ID) private platformId: Object) { }


  ngOnInit(): void
  {
    if (isPlatformBrowser(this.platformId)) {
      this.bannerList$ = this.bannerService.getAll().pipe(
        untilDestroyed(this),
        shareReplay(1)
      );
  
      this.bannerList$.subscribe(r =>
      {
        if (r.length) {
          this.slideConfig.autoplaySpeed = (r[0].appearTime || 2) * 1000;
        }
      });
  
      this.meta.addTag({ name: 'description', content: 'movie-banner' });
    }

  }

  ngOnDestroy(): void { }


  beforeChange($event: any): void
  {
    this.currentIndex = $event.nextSlide;
  }
}
