import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';

@Component({
   selector: 'app-movie-nav',
   templateUrl: './movie-nav.component.html',
   styleUrls: ['./movie-nav.component.scss']
})
export class MovieNavComponent implements OnChanges, OnInit
{
   @Input('items') items: { [key: string]: any }[];
   @Input('startIndex') startIndex: number;
   @Output() change = new EventEmitter<number>(true);

   activeIndex$: Observable<number>;


   ngOnChanges({ startIndex }: SimpleChanges): void
   {
      if (startIndex) {
         this.onItemClick(startIndex.currentValue || 0);
      }
   }

   ngOnInit(): void
   {
      this.activeIndex$ = this.change.asObservable().pipe(
         startWith(this.startIndex || 0)
      );
   }


   onItemClick(index: number): void
   {
      this.change.emit(index);
   }
}
