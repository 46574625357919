import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { ApiService } from '../api.service';
import { API_ENDPOINT_STAGING } from '../../../shared/helpers';
import { isPlatformBrowser } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class AdsService
{
  constructor(private apiService: ApiService
    ,@Inject(PLATFORM_ID) private platformId: Object) { }

  getAll()
  {
    if (isPlatformBrowser(this.platformId)) {
      return this.apiService.get(`${API_ENDPOINT_STAGING}/api/ads`);
    }
  }
}
