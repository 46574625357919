import { Directive, Input, HostBinding, OnInit } from '@angular/core';

@Directive({
  selector: '[appDisabledShowtime]'
})
export class DisabledShowtimeDirective implements OnInit {
  @HostBinding('class.disabled')
  isDisabled: boolean;

  @Input()
  showTimeDate: string;

  // constructor() { }
  now: Date = new Date();

  constructor() {
    // setInterval(() => {
    //   this.now = new Date();
    // }, 1);
  }

  ngOnInit() {
    // //console.log(this.showTimeDate);
    // //console.log(this.now);

    // if (this.showTimeDate < this.now) {
    //   //console.log('isDisabled', this.showTimeDate);
    //   this.isDisabled = true;
    // }
    const showTime = new Date(this.showTimeDate);

    if (showTime < this.now) {
      this.isDisabled = true;
    }
    //  else {
    //   //console.log("showTime date is NOT in the past");
    // }
  }
}
