import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApiService } from './services/api.service';
import { HttpClientModule } from '@angular/common/http';
import {
  AdsService,
  BannerService,
  MoviesService,
  SearchService,
  ShowTimeService,
  PomotionService,
  BookingService,
  SocialService,
  MetaService,
  GtmService
} from './services/http';

@NgModule({
  declarations: [],
  imports: [CommonModule, HttpClientModule],
  providers: [
    AdsService,
    ApiService,
    BannerService,
    MoviesService,
    PomotionService,
    SearchService,
    ShowTimeService,
    BookingService,
    SocialService,
    MetaService,
    GtmService
  ]
})
export class CoreModule {}
