import {
  Component,
  OnInit,
  HostListener,
  ViewChild,
  OnDestroy,
  Input, Inject, PLATFORM_ID
} from '@angular/core';
import { timer, Subscription, Observable, BehaviorSubject } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { CinemaInfo } from '../../../core/models';
import {
  trigger,
  state,
  style,
  transition,
  animate,
  group
} from '@angular/animations';
import { MoviesService, CinemaService } from 'src/app/core/services/http';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import {
  debounceTime,
  distinctUntilChanged,
  switchMap,
  shareReplay,
  filter
} from 'rxjs/operators';
import {
  NgForm,
  FormGroup,
  FormBuilder,
  FormArray,
  FormControl
} from '@angular/forms';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-quick-booking',
  templateUrl: './quick-booking.component.html',
  styleUrls: ['./quick-booking.component.scss'],
  animations: [
    trigger('triggerQuickBooking', [
      state(
        'true',
        style({
          transform: 'translateY(0)'
        })
      ),
      state(
        'false',
        style({
          transform: 'translateY(100%)'
        })
      ),
      transition('true=>false', animate('300ms ease-in-out')),
      transition('false=>true', animate('300ms ease-out'))
    ]),
    trigger('displaySelector', [
      state(
        'false',
        style({
          height: '0',
          opacity: '0'
        })
      ),

      state(
        'true',
        style({
          height: '*',
          opacity: '1'
        })
      ),

      transition('true => false', [
        group([
          animate(
            '200ms ease-in-out',
            style({
              opacity: '0'
            })
          ),
          animate(
            '200ms ease-in-out',
            style({
              height: '0'
            })
          )
        ])
      ]),
      transition('false => true', [
        group([
          animate(
            '200ms ease-in-out',
            style({
              height: '*'
            })
          ),
          animate(
            '200ms ease-in-out',
            style({
              opacity: '1'
            })
          )
        ])
      ])
    ])
  ]
})
export class QuickBookingComponent implements OnInit, OnDestroy {
  @ViewChild('movieForm', { static: false }) movieForm: NgForm;

  @Input() currentState: boolean;
  // currentState = 'hide';

  subscribeTimer: Subscription;

  isShowBranches = false;

  isShowMovies = false;

  mobileCurrentMovie = 'now showing';

  searchTerm = new BehaviorSubject<string>('');

  nowShowingList$: Observable<any[]>;

  brancheList$: Observable<any[]>;

  nearBy$:Observable<CinemaInfo>;
  cinenameInfo:CinemaInfo;

  quickBookingForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private moviesService: MoviesService,
    private cinemaService: CinemaService
    ,@Inject(PLATFORM_ID) private platformId: Object
  ) {

    if (isPlatformBrowser(this.platformId)) {
      this.nowShowingList$ = this.searchTerm.pipe(
        untilDestroyed(this),
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((term: string) => this.moviesService.getAvailable(term))
        // shareReplay(1)
      );
    }

    if (isPlatformBrowser(this.platformId)) {
      this.brancheList$ = this.cinemaService.list();
    }
  }

  ngOnInit() {
    this.quickBookingForm = this.formBuilder.group({
      movies: this.formBuilder.array([]),
      branches: this.formBuilder.array([])
    });
    let httpParams = new HttpParams();
    httpParams = httpParams.append('lat', '11.549612');
    httpParams = httpParams.append('lng', '104.934532');

    if (isPlatformBrowser(this.platformId)) {
      this.nearBy$ = this.cinemaService.nearBy(httpParams);
    }
    //this.getLocation();
  }

  ngOnDestroy() {}

  // #region action

  toggleShowMovies() {
    this.isShowMovies = !this.isShowMovies;

    if (this.isShowMovies) {
      this.isShowBranches = false;
    }
  }

  getLocation(): void{
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position)=>{
          const longitude = position.coords.longitude;
          const latitude = position.coords.latitude;
          ////console.log('find location found');
          ////console.log(position);
          let httpParams = new HttpParams();
          httpParams = httpParams.append('lat', latitude+'');
          httpParams = httpParams.append('lng', longitude+'');

          if (isPlatformBrowser(this.platformId)) {
            this.nearBy$ = this.cinemaService.nearBy(httpParams);
            this.nearBy$.subscribe(event => {
              this.cinenameInfo = event;
            });
          }
        });
    } else {
       //("No support for geolocation")
       let httpParams = new HttpParams();
       httpParams = httpParams.append('lat', '11.549612');
       httpParams = httpParams.append('lng', '104.934532');

        if (isPlatformBrowser(this.platformId)) {
          this.nearBy$ = this.cinemaService.nearBy(httpParams);
        }
       ////console.log(this.nearBy$);
    }
  }
  toggleShowBranches() {
    this.isShowBranches = !this.isShowBranches;

    if (this.isShowBranches) {
      this.isShowMovies = false;
    }
  }

  private closeOverlay() {
    this.isShowBranches = false;
    this.isShowMovies = false;
  }

  // #endregion

  // #region form event

  onChangeMovie(movieId: string, isChecked: boolean) {
    const movies = this.quickBookingForm.controls.movies as FormArray;

    if (isChecked) {
      movies.push(new FormControl(movieId));
    } else {
      const index = movies.controls.findIndex(x => x.value == movieId);
      movies.removeAt(index);
    }
  }

  onChangeBranche(brancheId: string, isChecked: boolean) {
    const branches = this.quickBookingForm.controls.branches as FormArray;

    if (isChecked) {
      branches.push(new FormControl(brancheId));
    } else {
      const index = branches.controls.findIndex(x => x.value == brancheId);
      branches.removeAt(index);
    }
  }

  search(search: string = '') {
    this.searchTerm.next(search);
  }

  clearSearch() {
    this.searchTerm.next('');
  }

  goShowTime() {
    this.closeOverlay();

    const params: {
      movies?: string[];
      cinemas?: string[];
    } = {};

    if (this.quickBookingForm.value.movies.length) {
      params.movies = this.quickBookingForm.value.movies;
    }

    if (this.quickBookingForm.value.branches.length) {
      params.cinemas = this.quickBookingForm.value.branches;
    }

    ////console.log(params);

    this.router.navigate(['/showtime'], {
      queryParams: params
    });
  }

  // #endregion
}
