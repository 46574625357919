import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subject, Observable } from 'rxjs';
import { SearchService } from 'src/app/core/services/http';
import {
  debounceTime,
  distinctUntilChanged,
  switchMap,
  shareReplay
} from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('triggerNavBar', [
      state(
        'true',
        style({
          transform: 'translateY(0)'
        })
      ),
      state(
        'false',
        style({
          transform: 'translateY(-100%)'
        })
      ),
      transition('true=>false', animate('300ms ease-in-out')),
      transition('false=>true', animate('300ms ease-out'))
    ]),
    trigger('triggerMobileContent', [
      state(
        'true',
        style({
          transform: 'translateY(0)'
        })
      ),
      state(
        'false',
        style({
          transform: 'translateY(calc(-100% - 70px))'
        })
      ),
      transition('true=>false', animate('300ms ease-in-out')),
      transition('false=>true', animate('300ms ease-out'))
    ])
  ]
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() appDetail: any;

  @Input() currentState: boolean;

  constructor(
    private $translate: TranslateService,
    private searchService: SearchService
  ) {
    this.langs = this.$translate.getLangs();
    this.lang = this.$translate.currentLang;
  }

  readonly langs: string[];

  lang: string;
  langDisplay:string ="en";
  navbarOpen = false;
  searchOpen = false;

  searchTerm = new Subject<string>();
  resultList$: Observable<any[]>;

  ngOnInit() {
    this.$translate.onLangChange
      .asObservable()
      .subscribe(u => (this.lang = u.lang));

    this.resultList$ = this.searchTerm.pipe(
      untilDestroyed(this),
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((term: string) => this.searchService.search(term)),
      shareReplay(1)
    );
  }

  ngOnDestroy() {}

  search(search: string = '') {
    ////console.log(search);
    this.searchTerm.next(search);
  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  toggleSearchBar(closeNavbar = false) {
    this.searchTerm.next('');
    this.searchOpen = !this.searchOpen;
    this.navbarOpen = closeNavbar ? false : this.navbarOpen;
  }

  onLangChange(lang: string): void {
    if(lang == 'kh'){
      this.langDisplay = 'LA';
    }
    else{
      this.langDisplay = 'EN';
    }
    this.$translate.use(lang);
  }
}
