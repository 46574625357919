import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { isArray } from 'util';
import { MoviePage2 } from '../../../core/models';
import { isTrue } from '../../../shared/helpers';

declare var $: any;

@Component({
  selector: 'app-movie-poster2',
  templateUrl: './movie-poster2.component.html',
  styleUrls: ['./movie-poster2.component.scss']
})
export class MoviePoster2Component implements OnChanges
{
  @Input('movies') movies: MoviePage2 | MoviePage2[];
  @Input('step') step: number = 5;
  @Input('singleRow') singleRow: boolean = false;
  @Input('responsive') responsive: boolean = true;

  models: MoviePage2[];


  ngOnChanges({ movies, singleRow, responsive }: SimpleChanges): void
  {
    const collectMovies = () => isArray(this.movies) ? this.movies : (
      this.movies ? [this.movies] : []
    );
    let models = [];

    if (movies) {
      models = collectMovies();
    }

    if (singleRow) {
      this.singleRow = isTrue(singleRow.currentValue);
    }

    if (responsive) {
      this.responsive = isTrue(responsive.currentValue);
    }

    // Clone the movies
    this.models = [...models];
  }


  onSlideNext(): void
  {
    const $slider = $('#poster-slider');
    const $poster = $slider.find('.poster-slider-item:first');

    if (!$poster.length) { return; }

    const scrollLeft = $slider.scrollLeft();
    let scrollNext = (scrollLeft + ($poster.outerWidth() * this.step));

    if (scrollLeft >= ($slider.prop('scrollWidth') - $slider.outerWidth())) {
      scrollNext = 0;
    }

    $slider.animate({ scrollLeft: scrollNext });
  }
}
