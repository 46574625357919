import { Component, HostBinding, Input, OnDestroy } from '@angular/core';
import { ShareService } from '../../services';

declare var FB: any;

@Component({
  selector: 'app-share-link',
  templateUrl: './share-link.component.html'
})
export class ShareLinkComponent implements OnDestroy
{
  @HostBinding('class.app-share-link') hostCss = true;
  @Input('uri') uri?: string;
  @Input('title') title?: string;
  @Input('description') description?: string;
  @Input('image') image?: string;
  @Input('hint') hint?: string;
  @Input('icon') icon?: string;
  @Input('iconCss') iconCss?: string;

  constructor(private $share: ShareService) { }


  ngOnDestroy(): void
  {
    this.$share.restore('facebook');
  }


  onShare($event: Event): void
  {
    $event.preventDefault();

    const props = {
      'og:type': 'website',
      'og:url': this.uri || window.location.href,
      'og:title': this.title || '',
      'og:description': this.description || '',
      'og:image': this.image || ''
    };

    this.$share.commit('facebook', this.title || '', props);

    FB.ui({
      method: 'share_open_graph',
      action_type: 'og.shares',
      action_properties: JSON.stringify({ object: props })
    });
  }
}
