import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HighlightComponent, PaginatorComponent, ShareLinkComponent, TagComponent } from './components';
import { QuickBookingComponent } from './components/quick-booking/quick-booking.component';
import { ScrollToTopComponent } from './components/scroll-to-top/scroll-to-top.component';
import { DigitValidatorDirective, DisabledShowtimeDirective, DragScrollDirective } from './directives';
import { FooterComponent } from './layout/footer/footer.component';
import { HeaderComponent } from './layout/header/header.component';
import { DashPipe, EmptyPipe, ResolveAndSafePipe, SafePipe, TranslateToKHPipe, TranslateToPipe, ValPipe } from './pipes';

export function HttpLoaderFactory($http: HttpClient)
{
  return new TranslateHttpLoader($http);
}

@NgModule({
  declarations: [
    DragScrollDirective,
    QuickBookingComponent,
    ScrollToTopComponent,
    ValPipe,
    EmptyPipe,
    DashPipe,
    SafePipe,
    ResolveAndSafePipe,
    TranslateToPipe,
    TranslateToKHPipe,

    HeaderComponent,
    FooterComponent,

    HighlightComponent,
    PaginatorComponent,
    TagComponent,
    ShareLinkComponent,

    DisabledShowtimeDirective,
    DigitValidatorDirective
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: false
    })
  ],
  exports: [
    TranslateModule,

    DragScrollDirective,
    QuickBookingComponent,
    ScrollToTopComponent,
    ValPipe,
    EmptyPipe,
    DashPipe,
    SafePipe,
    ResolveAndSafePipe,
    TranslateToPipe,
    TranslateToKHPipe,

    HeaderComponent,
    FooterComponent,

    HighlightComponent,
    PaginatorComponent,
    TagComponent,
    ShareLinkComponent,

    DisabledShowtimeDirective,
    DigitValidatorDirective
  ]
})
export class SharedModule { }
