import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { MoviePage } from '../../models/movie';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class MoviesService {
  constructor(private apiService: ApiService) {}

  // TODO : mock api เข้าเครื่องตัวเองอยู่
  getAll(query: any = {}): Observable<MoviePage[]> {
    return this.apiService
      .get(
        'https://www.majorplatinumcineplex.la/api/movie',
        new HttpParams({
          fromObject: query
        })
      )
      .pipe(
        map(data => {
          //console.group('MoviesService getAll movie');
          //console.group('movie api raw data');
          ////console.log(data);
          //console.groupEnd();

          var movies: MoviePage[] = [];

          while (data.length) {
            let temp = data.splice(0, 10);

            movies.push({
              list1: temp.slice(0, 5),
              list2: temp.slice(5, 10)
            });
          }

          //console.group('movie after map to MoviePage');
          ////console.log(movies);
          //console.groupEnd();
          //console.groupEnd();
          return movies;
        })
      );
  }

  getAvailable(search: string = ''): Observable<any[]> {
    const params = {
      search: search,
      category: ''
    };

    let nowShowing = this.apiService.get(
      'https://www.majorplatinumcineplex.la/api/movie',
      new HttpParams({
        fromObject: {
          search: search,
          category: 'now showing'
        }
      })
    );

    let comingSoon = this.apiService
      .get(
        'https://www.majorplatinumcineplex.la/api/movie',
        new HttpParams({
          fromObject: {
            search: search,
            category: 'coming soon'
          }
        })
      )
      .pipe(
        map(data => {
          return data.filter(data => data.isAdvancedTicket === 1);
        })
      );

    let imax = this.apiService.get(
      'https://www.majorplatinumcineplex.la/api/movie',
      new HttpParams({
        fromObject: {
          search: search,
          category: 'imax'
        }
      })
    );

    let fourdx = this.apiService.get(
      'https://www.majorplatinumcineplex.la/api/movie',
      new HttpParams({
        fromObject: {
          search: search,
          category: '4dx'
        }
      })
    );

    return forkJoin([nowShowing, comingSoon, imax, fourdx]).pipe(
      map(([now, com, ima, fou]) => {
        const result = [
          {
            name: 'now showing',
            movies: now
          },
          {
            name: 'coming soon',
            movies: com
          },
          {
            name: 'imax',
            movies: ima
          },
          {
            name: '4dx',
            movies: fou
          }
        ];

        return result;
      })
    );
  }
}
