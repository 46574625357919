import { Pipe, PipeTransform } from '@angular/core';
import { ValPipe } from './val.pipe';

@Pipe({
   name: 'dash'
})
export class DashPipe extends ValPipe implements PipeTransform
{
   transform(value: any, args?: any): any
   {
      return super.transform(value, '-');
   }
}
