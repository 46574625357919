import { Pipe, PipeTransform } from '@angular/core';
import { SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl } from '@angular/platform-browser';
import { isString } from 'util';
import { resolveUrl } from '../helpers';
import { SafePipe } from './safe.pipe';

@Pipe({ name: 'resolveAndSafe' })
export class ResolveAndSafePipe extends SafePipe implements PipeTransform
{
  transform(value: any, type: string, defaultValue?: any): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl
  {
    if (!isString(value) || !value.length) {
      return defaultValue;
    }

    return super.transform(resolveUrl(value), type);
  }
}
