import { Directive, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

@Directive({ selector: '[appDragScroll]' })
export class DragScrollDirective implements OnChanges, OnInit, OnDestroy
{
   @Input('appDragScrollOptions') options?: { [key: string]: any };
   @Output() dragScrollActive = new EventEmitter<boolean>(true);
   @Output() dragScrollChange = new EventEmitter<{ x: number, y: number }>(true);

   constructor(
      private $renderer: Renderer2,
      private $ref: ElementRef,
   )
   {
      this.$node = this.$ref.nativeElement;

      this._options = { ...this.defaultOptions };

      this.dragScrollActive.pipe(
         untilDestroyed(this)
      ).subscribe(u =>
      {
         this._isActive = u;
         this._updateCursor();
      });

      this.dragScrollChange.pipe(
         untilDestroyed(this)
      ).subscribe(u => this._translate(u));
   }

   readonly $node: any;
   readonly defaultOptions: { [key: string]: any } = {
      widgetCssClass: 'app-drag-scroll',
      direction: 'x',
      step: 3
   };

   private _options: { [key: string]: any };
   private _isActive: boolean = false;
   private _startPosition: { x: number, y: number } = { x: null, y: null };
   private _scrollPosition: { x: number, y: number } = { x: null, y: null };


   @HostListener('mousedown', ['$event']) onMousedown($event: MouseEvent): void
   {
      this.dragScrollActive.emit(true);

      this._startPosition = {
         x: ($event.pageX - this.$node.offsetLeft),
         y: ($event.pageY - this.$node.offsetTop)
      };
      this._scrollPosition = {
         x: this.$node.scrollLeft,
         y: this.$node.scrollTop
      };
   }

   @HostListener('mouseleve', ['$event']) onMouseleave($event: MouseEvent): void
   {
      this.dragScrollActive.emit(false);
   }

   @HostListener('mouseup', ['$event']) onMouseup($event: MouseEvent): void
   {
      this.dragScrollActive.emit(false);
   }

   @HostListener('mousemove', ['$event']) onMousemove($event: MouseEvent): void
   {
      if (!this._isActive) { return; }

      $event.preventDefault();

      this.dragScrollChange.emit({
         x: ($event.pageX - this.$node.offsetLeft),
         y: ($event.pageY - this.$node.offsetTop)
      });
   }


   ngOnChanges({ options }: SimpleChanges): void
   {
      if (options) {
         try {
            this._options = {
               ...this.defaultOptions,
               ...JSON.parse(options.currentValue || '{}')
            };
         } catch (ignored) { }

         this._updateStyle(options.previousValue);
      }
   }

   ngOnInit(): void
   {
      this._updateStyle();
   }

   ngOnDestroy(): void { }


   private _updateStyle(prevOptions?: { [key: string]: any }): void
   {
      if (prevOptions) {
         try {
            this.$renderer.removeClass(this.$node, 'overflow-invisible');
            this.$renderer.removeClass(this.$node, prevOptions.widgetCssClass);
         } catch (ignored) { }
      }

      this.$renderer.addClass(this.$node, 'overflow-invisible');
      this.$renderer.addClass(this.$node, this._options.widgetCssClass);
   }

   private _updateCursor(): void
   {
      if (this._isActive) {
         this.$renderer.setStyle(this.$node, 'cursor', 'grabbing');
         this.$renderer.setStyle(this.$node, 'cursor', '-webkit-grabbing');
      } else {
         this.$renderer.setStyle(this.$node, 'cursor', 'default');
      }
   }


   private _translate(position: { x: number, y: number }): void
   {
      const translate = (distance: number, direction: 'x' | 'y') => ((distance - this._startPosition[direction]) * this._options.step);
      const target = (distance: number, direction: 'x' | 'y') => (this._scrollPosition[direction] - translate(distance, direction));

      if (this._options.direction === 'y') {
         this.$node.scrollTop = target(position.y, 'y');
      } else {
         this.$node.scrollLeft = target(position.x, 'x');
      }
   }
}
