import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';
import { finalize, shareReplay } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { ContactUsInfo } from '../../../core/models';
import { ContactUsService } from '../../../core/services/http';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit, OnDestroy
{
  constructor(
    private $formBuilder: FormBuilder,
    private $contactUsService: ContactUsService,
    private $translate: TranslateService
  ) { }

  info$: Observable<ContactUsInfo>;
  form: FormGroup;
  loading: boolean = false;
  success: boolean = false;


  ngOnInit(): void
  {
    this.info$ = this.$contactUsService.info().pipe(
      shareReplay(1)
    );

    this.form = this.$formBuilder.group({
      name: [{ value: '', disabled: this.loading }, [
        Validators.required,
        Validators.maxLength(254),
        // Validators.pattern(/^[a-zA-Z\u1780-\u17FF]+$/)
        Validators.pattern(/^[a-z]+$/i)
      ]],
      lastname: [{ value: '', disabled: this.loading }, [
        Validators.required,
        Validators.maxLength(254),
        // Validators.pattern(/^[a-zA-Z\u1780-\u17FF]+$/)
        Validators.pattern(/^[a-z]+$/i)
      ]],
      email: [{ value: '', disabled: this.loading }, [Validators.required, Validators.email, Validators.maxLength(128)]],
      phone_number: [{ value: '', disabled: this.loading }, [Validators.required, Validators.pattern(/^\d{9,10}$/)]],
      topic: [{ value: '', disabled: this.loading }, [Validators.required, Validators.maxLength(128)]],
      message: [{ value: '', disabled: this.loading }, Validators.maxLength(510)]
    });
  }

  ngOnDestroy(): void { }


  onSubmit(): void
  {
    Object.keys(this.form.controls).forEach(name =>
    {
      const control = this.form.get(name);
      control.markAsDirty({ onlySelf: true });
      control.markAsUntouched({ onlySelf: true });
      control.updateValueAndValidity({ emitEvent: false });
    });

    if (this.form.invalid) { return; }

    const payload = new FormData();

    for (const [u, v] of Object.entries(this.form.value)) {
      payload.append(u, `${v}`);
    }

    this.loading = true;
    this.success = false;

    this.$contactUsService.send(payload).pipe(
      untilDestroyed(this),
      finalize(() => this.loading = false)
    ).subscribe(
      () =>
      {
        this.success = true;
        this.form.reset();

        setTimeout(() => this.success = false, 10000);
      },
      console.error
    );
  }


  hasErrors(name?: string, error?: string): boolean
  {
    let control = this.form as AbstractControl;

    if (name) {
      control = this.form.get(name);
    }

    let invalid = (control.invalid && (control.dirty || control.touched));

    if (invalid && error) {
      return control.hasError(error);
    }

    return invalid;
  }

  hasValues(name?: string): boolean
  {
    let control = this.form as AbstractControl;

    if (name) {
      control = this.form.get(name);
    }

    return !isNullOrUndefined(control.value) && (control.value.hasOwnProperty('length') && control.value.length > 0);
  }
}
