export * from './ads-area/ads-area.component';
export * from './ads-popup/ads-popup.component';
export * from './banner/banner.component';
export * from './movie-detail/movie-detail.component';
export * from './movie-detail2/movie-detail2.component';
export * from './movie-nav/movie-nav.component';
export * from './movie-poster/movie-poster.component';
export * from './movie-poster2-header/movie-poster2-header.component';
export * from './movie-poster2/movie-poster2.component';
export * from './promotion/promotion.component';
