import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ShowTimeService {
  constructor(private apiService: ApiService) {}

  getAll(query: any = {}): Observable<any[]> {
    return this.apiService
      .get(
        'https://www.majorplatinumcineplex.la/api/show-movie',
        new HttpParams({
          fromObject: query
        })
      )
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  getMovieShowtimeDate(
    query: {
      movie?: string;
      cinema?: string;
    } = {}
  ): Observable<string[]> {
    return this.apiService
      .get(
        'https://www.majorplatinumcineplex.la/api/date-show-movie',
        new HttpParams({
          fromObject: query
        })
      )
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  getOrderDetail(transactionNumber: string) {
    return this.apiService
      .get(`https://www.majorplatinumcineplex.la/api/order/${transactionNumber}`)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  getSeatPlan(query: { cinemaId: string; sessionId: string }) {
    return this.apiService
      .get(
        'https://www.majorplatinumcineplex.la/api/seat',
        new HttpParams({
          fromObject: query
        })
      )
      .pipe(
        map(data => {
          //console.group('Get seat plan');
          ////console.log('Raw Seat Plan Data : ', data);
          this.sortSeat(data);
          ////console.log('Seat Plan after sort : ', data);
          //console.groupEnd();
          return data;
        })
      );
  }

  private sortSeat(seatPlan: any[]): any[] {
    const sortAlpha = value => {
      return value.sort((x, y) => {
        if (x.alphabet < y.alphabet) {
          return 1;
        } else if (x.alphabet > y.alphabet) {
          return -1;
        }
        return 0;
      });
    };

    const sortVIP = value => {
      return value.sort((x, y) => {
        if (x.alphabet.length < y.alphabet.length) {
          return -1;
        } else if (x.alphabet.length > y.alphabet.length) {
          return 1;
        }
        return 0;
      });
    };

    return sortVIP(sortAlpha(seatPlan));
  }
}
