import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { HomeModule } from './home/home.module';
import { HttpLoaderFactory, SharedModule } from './shared/shared.module';
import { FacebookModule, FacebookService } from 'ngx-facebook';

@NgModule({
   declarations: [AppComponent],
   imports: [
      BrowserModule.withServerTransition({ appId: 'serverApp' }),
      AppRoutingModule,
      BrowserAnimationsModule,
      FacebookModule.forRoot(),

      /**
       * CoreModule รวมพวก Services หลักๆ เช่น API Service
       */
      CoreModule,

      TranslateModule.forRoot({
         loader: {
            provide: TranslateLoader,
            useFactory: (HttpLoaderFactory),
            deps: [HttpClient]
         },
         isolate: false
      }),

      /**
       * SharedModule ไว้เก็บพวก utility ต่างๆ เช่น components กลางๆ แต่ใช้ทุกคน หรือ pipe, directiive, enum, interface
       *
       * eg. Quick booking
       */
      SharedModule,

      /**
       * HomeModule หน้าเว็บหลักเลยเอามาให้โหลดเลยไม่ต้อง lazy load เข้าเว็บต้องเจอหน้านี้แน่ๆ
       */
      HomeModule
   ],
   providers: [FacebookService],
   bootstrap: [AppComponent]
})
export class AppModule { }
