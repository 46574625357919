import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  OnDestroy, Inject, PLATFORM_ID
} from '@angular/core';
import { MoviesService } from 'src/app/core/services/http/movies.service';
import { switchMap, map, finalize } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { CinemaSystemType } from '../../../core/models/cinema_system_type.model';
import { CinemaSystemTypeService } from '../../../core/services/http/cinema_system_type.service';
import { ActivatedRoute } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-movie-poster',
  templateUrl: './movie-poster.component.html',
  styleUrls: ['./movie-poster.component.scss']
})
export class MoviePosterComponent implements OnInit, OnDestroy {
  categories = [
    { id: 'now showing', nameKh: 'ກໍາລັງສາຍ', nameEn: 'now showing' },
    { id: 'coming soon', nameKh: 'ເຂົ້າສາຍໄວໆນີ້', nameEn: 'coming soon' }
  ];

  slideConfig = {
    arrows: false,
    infinite: true,
    // variableWidth: true
  };
  currentCategoryIndex = 0;

  @ViewChild('moviePoster', { static: false }) moviePoster;

  movieList$: Observable<any[]>;

  categoryTerm = new BehaviorSubject<String>('now showing');

  info$: Observable<CinemaSystemType>;

  trailerUrl: string;

  @ViewChild('trailer', { static: false, read: ElementRef })
  private trailerRef: ElementRef<HTMLVideoElement>;

  constructor(
    private route: ActivatedRoute,
    private moviesService: MoviesService,
    private $cinemaSystemTypeService: CinemaSystemTypeService
    ,@Inject(PLATFORM_ID) private platformId: Object
  ) {

    if (isPlatformBrowser(this.platformId)) {
      this.movieList$ = this.categoryTerm.pipe(
        switchMap((category: string) => this.moviesService.getAll({ category })),
        map(data => {
          return data;
        })
      );
    }
  }

  ngOnInit() {

    if (isPlatformBrowser(this.platformId)) {
      this.$cinemaSystemTypeService
        .info()
        .pipe(
          //untilDestroyed(this)
          finalize(() => {
            this.selectCategory();
          })
        )
        .subscribe(u => {
          for (var i = 0; i < u.length; i++) {
            this.categories.push(u[i]);
          }
          ////console.log('get cinema system type');
          ////console.log(this.categories);
        });
      }
  }

  ngOnDestroy(): void {}

  private selectCategory() {
    this.route.queryParams
      .pipe(
        untilDestroyed(this),
        map(res => {
          if (res.category) {
            const categoryIndex = this.categories.findIndex(
              x => x.nameEn.toLowerCase() === res.category.toLowerCase()
            );
            this.changeCategory(categoryIndex);
          }
          return res;
        })
      )
      .subscribe(res => {});
  }

  private reloadMovies(categoryIndex: number = 0) {
    const category = this.categories[categoryIndex].id;

    this.categoryTerm.next(category);
  }

  nextSlide() {
    this.moviePoster.slickNext();
  }

  changeCategory($event) {
    this.currentCategoryIndex = $event;
    this.reloadMovies($event);
  }

  playTrailer($event) {
    this.trailerUrl = $event;

    setTimeout(() => {
      try {
        /**
         * @see https://stackoverflow.com/questions/36803176/how-to-prevent-the-play-request-was-interrupted-by-a-call-to-pause-error
         */
        const isPlaying =
          this.trailerRef.nativeElement.currentTime > 0 &&
          !this.trailerRef.nativeElement.paused &&
          !this.trailerRef.nativeElement.ended &&
          this.trailerRef.nativeElement.readyState > 2;

        if (!isPlaying) {
          // this.trailerRef.nativeElement.muted = true;
          this.trailerRef.nativeElement.play();
        }
      } catch (ignored) {}
    }, 1000);
  }
}
