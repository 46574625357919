import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_ENDPOINT_STAGING } from '../../../shared/helpers';
import { CinemaSystemType } from '../../models/cinema_system_type.model';
import { ApiService } from '../api.service';

@Injectable({ providedIn: 'root' })
export class CinemaSystemTypeService
{
   constructor(private $apiService: ApiService) { }
   get basePath() { return `${API_ENDPOINT_STAGING}/api/cinema_system_type`; }
   info(params?: HttpParams): Observable<CinemaSystemType[]>
   {
      return this.$apiService.get(this.basePath, params);
   }
}
