import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { API_ENDPOINT_STAGING } from '../../../shared/helpers';
import { CinemaInfo } from '../../models';
import { ApiService } from '../api.service';

@Injectable({ providedIn: 'root' })
export class CinemaService
{
   constructor(private $apiService: ApiService) { }

   private _list$: Observable<CinemaInfo[]>;
   private info$: Observable<CinemaInfo>;

   get basePath() { return `${API_ENDPOINT_STAGING}/api/cinema`; }
   get basePathNearBy() { return `${API_ENDPOINT_STAGING}/api/nearby`; }


   private _list(params?: HttpParams, cache?: boolean): Observable<CinemaInfo[]>
   {
      let list$ = this.$apiService.get(this.basePath, params).pipe(
         map(r => r.items as CinemaInfo[])
      );

      if (cache) {
         if (!this._list$) {
            this._list$ = list$.pipe(
               shareReplay(1)
            );
         }

         list$ = this._list$;
      }

      return list$;
   }


   nearBy(params?: HttpParams): Observable<CinemaInfo>
   {
      let info$ = this.$apiService.get(this.basePathNearBy, params);
      return info$;
   }

   list(params?: HttpParams, cache?: boolean): Observable<CinemaInfo[]>
   {
      return this._list(params, cache);
   }

   read(id: number | string, params?: HttpParams): Observable<CinemaInfo>
   {
      return this.$apiService.get(`${this.basePath}/${id}`, params);
   }
}
