import { NgModule } from '@angular/core';
import { PreloadingStrategy } from "@angular/router";
import { Route }              from "@angular/router";
import { Observable, timer }  from "rxjs";
import { of }                 from 'rxjs/internal/observable/of';
import { flatMap }            from "rxjs/internal/operators";
import { RouterModule, Routes, ExtraOptions, PreloadAllModules } from '@angular/router';

export class PreloadPublicModules implements PreloadingStrategy {
  preload(route : Route, load : Function) : Observable<any> {
    const loadRoute = (delay) => delay ? timer(5000).pipe(flatMap(_ => load())) : load();
    return route.data && route.data.preload ? loadRoute(5000) : of(null);
  }
}
const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },

  {
    path: 'showtime',
    loadChildren: () =>
      import('./showtime/showtime.module').then(m => m.ShowtimeModule)
  }

];

const routerOptions: ExtraOptions = {
  // log all internal navigation events to the console
  // enableTracing: true,

  useHash: false,

  // This value is required for server-side rendering to work.
  initialNavigation: 'enabled',

  scrollPositionRestoration: 'enabled',

  anchorScrolling: 'enabled',

  scrollOffset: [0, 85],

  onSameUrlNavigation: 'reload',

  preloadingStrategy: PreloadAllModules
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
