import { HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';

export function toHttpParams(params: { [key: string]: any } | HttpParams): HttpParams
{
  if (params instanceof HttpParams) { return params; }

  let out = new HttpParams();

  for (const [k, v] of Object.entries(params || {})) {
    out = out.set(k, v);
  }

  return out;
}

export function resolveUrl(path: string, base?: string): string
{
  return (environment.production || /^https?/.test(path) ? path : `${base || API_ENDPOINT_STAGING}/${path}`);
}

export function getApiEndpointStaging(): string
{
  try {
    if (environment['staging'] && environment['staging'] === 'test') {
      return environment['apiEndpointTest'];
    }
  } catch (ignored) { }

  return environment.apiEndpoint;
}

export const API_ENDPOINT_STAGING = getApiEndpointStaging();