import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, Renderer2, ViewChild,PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subscription, timer } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { SocialService,MetaService,GtmService } from './core/services/http';
import { FooterComponent } from './shared/layout/footer/footer.component';
import { Meta, Title } from '@angular/platform-browser';
import * as $ from 'jquery';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('wrapper', { read: ElementRef, static: false }) $wapper: ElementRef<HTMLDivElement>;
  @ViewChild(FooterComponent, { read: ElementRef, static: false }) $footer: ElementRef<HTMLElement>;
  constructor(
    private router: Router,
    private $renderer: Renderer2,
    @Inject(DOCUMENT) private $document: Document,
    private $translate: TranslateService,
    private socialService: SocialService,
    private metaService:MetaService,
    private gtmService: GtmService,
    private titleHeader: Title, private meta: Meta
    ,@Inject(PLATFORM_ID) private platformId: Object
  )
  {
    /**
     * @see http://www.ngx-translate.com
     */

    // Add languages and default use
    this.$translate.addLangs(this.langs);
    this.$translate.setDefaultLang(this.defaultLang);

    // Use language from locale browser
    const lang = this.$translate.getBrowserLang();
    this.$translate.use(
      this.langs.indexOf(lang) > -1 ? lang : this.defaultLang
    );
  }

  readonly langs = ['en', 'kh'];
  readonly defaultLang = 'en';

  title = 'major-kh';

  appDetail = {
    socials: [
      {
        name: 'facebook',
        icon: 'facebook.svg',
        url: 'https://www.facebook.com/majorcineplexcambodia'
      },
      {
        name: 'line',
        icon: 'line.svg',
        url: '#'
      },
      {
        name: 'what app',
        icon: 'what-app.svg',
        url: '#'
      },
      {
        name: 'instagram',
        icon: 'instagram.svg',
        url: 'https://instagram.com/majorkh'
      }
    ],

    appStore: [
      {
        name: 'Play Store',
        icon: 'play-store.svg',
        url: '#'
      },
      {
        name: 'Apple Store',
        icon: 'apple-store.svg',
        url: '#'
      }
    ],

    tell: '023-901111'
  };

  currentState = true;
  currentStateToTop = false;
  subscribeTimer: Subscription;

  isDisabledQuickBooking = false;
  isDisabledHeader = false;

  // social$: Observable<any[]>;

  ngOnInit(): void
  {


    if (isPlatformBrowser(this.platformId)) {
      // append to head of document
      var gtm = this.gtmService.addGtmScripts();
      this.router.events
        .pipe(filter(event => event instanceof NavigationEnd))
        .subscribe(event =>
        {
          if (this.router.url.includes('/showtime') || this.router.url.includes('/underconstruction')) {
            if (this.router.url.includes('/underconstruction')) {
              this.isDisabledHeader = true;
            }
            this.isDisabledQuickBooking = true;
          } else {
            var page = 'HOME';
            var mainPage = true;
            ////console.log(this.router.url);
            if(this.router.url.includes('/cinema')){
              var url = this.router.url;
              var temp = url.split('/');
              if(temp.length > 2){
                this.metaService.addTagsCinema(temp[2]);
                mainPage = false;
              }
              else{
                page = 'CINEMA';
              }
            }
            else if(this.router.url.includes('/promotions')){
              var url = this.router.url;
              var temp = url.split('/');
              if(temp.length > 2){
                this.metaService.addTagsPromotion(temp[2]);
                mainPage = false;
              }
              else{
                page = 'PROMOTIONS';
              }
            }
            else if(this.router.url.includes('/news-and-activity')){
              var url = this.router.url;
              var temp = url.split('/');
              if(temp.length > 2){
                this.metaService.addTagsNews(temp[2]);
                mainPage = false;
              }
              else{
                page = 'NEWS & ACTIVITIES';
              }
            }
            else if(this.router.url.includes('/contact-us')){
              page = 'CONTACT US';
            }
            else if(this.router.url.includes('/movies')){
              var url = this.router.url;
              var temp = url.split('/');
              this.metaService.addTagsMovie(temp[2]);
              mainPage = false;
            }
            if(mainPage){
              //this.metaService.addTags(page);
            }
            //this.meta.updateTag({ name: 'title', content: this.router.url });
            //this.meta.updateTag({ name: 'description', content: this.router.url });
            this.isDisabledQuickBooking = false;
            this.isDisabledHeader = false;
          }

          // this.toggleQuickBookingBar();
        });

      this.socialService.getAll().pipe(
        map(x =>
        {
          this.appDetail.socials = x.filter(y => y.disabled === 0);
        })
      ).subscribe();

      // this.social$.subscribe(res => {
      //   //console.log(res);
      //   debugger;
      // });
    }
  }


  ngAfterViewInit(): void
  {
    this.$translate.onLangChange.asObservable().subscribe(u =>
    {
      this.langs.forEach(v =>
        this.$renderer.removeClass(this.$document.body, `lang-${v}`)
      );
      this.$renderer.addClass(this.$document.body, `lang-${u.lang}`);
    });

    this._updateWrapperStyles();
  }

  ngOnDestroy(): void { }


  @HostListener('window:resize')
  onWindowResize(): void
  {
    this._updateWrapperStyles();
  }

  @HostListener('scroll', ['$event'])
  scrollHandler($event)
  {
    if($event.srcElement.scrollTop > 1000){
      this.currentStateToTop = true;
    }
    else{
      this.currentStateToTop = false;
    }
    this.toggleBar();
  }


  private _updateWrapperStyles()
  {
    if (this.$wapper && this.$footer) {
      const offsetHeight = this.$footer.nativeElement.offsetHeight;
      this.$wapper.nativeElement.style.minHeight = `calc(100% - ${offsetHeight}px)`;
    }
  }

  private toggleBar()
  {
    this.currentState = false;
    if (this.subscribeTimer) {
      this.subscribeTimer.unsubscribe();
    }

    this.subscribeTimer = timer(950)
      .pipe(untilDestroyed(this))
      .subscribe(x =>
      {
        this.currentState = true;
      });
  }
}
