import { Pipe, PipeTransform } from '@angular/core';
import { ValPipe } from './val.pipe';

@Pipe({
   name: 'empty'
})
export class EmptyPipe extends ValPipe implements PipeTransform
{
   transform(value: any, args?: any): any
   {
      return super.transform(value, '');
   }
}
