import { Component, OnInit, Inject, HostListener, Input } from '@angular/core';
import { DOCUMENT,ViewportScroller } from '@angular/common';

import {
  trigger,
  state,
  style,
  transition,
  animate,
  group
} from '@angular/animations';
@Component({
  selector: 'app-scroll-to-top',
  templateUrl: './scroll-to-top.component.html',
  styleUrls: ['./scroll-to-top.component.scss'],
  animations: [
    trigger('triggerToTop', [
      state(
        'true',
        style({
          opacity:"1",
          bottom:"80px",
          transform: 'translateY(0)'
        })
      ),
      state(
        'false',
        style({
          opacity: '0',
          bottom:"0px",
          transform: 'translateY(0)'
        })
      ),
      transition('true=>false', animate('300ms ease-in-out')),
      transition('false=>true', animate('300ms ease-out'))
    ]),
    trigger('displaySelector', [
      state(
        'false',
        style({
          height: '0',
          opacity: '0'
        })
      ),

      state(
        'true',
        style({
          height: '*',
          opacity: '1'
        })
      ),

      transition('true => false', [
        group([
          animate(
            '200ms ease-in-out',
            style({
              opacity: '0'
            })
          ),
          animate(
            '200ms ease-in-out',
            style({
              height: '0'
            })
          )
        ])
      ]),
      transition('false => true', [
        group([
          animate(
            '200ms ease-in-out',
            style({
              height: '*'
            })
          ),
          animate(
            '200ms ease-in-out',
            style({
              opacity: '1'
            })
          )
        ])
      ])
    ])
  ]
})
export class ScrollToTopComponent implements OnInit {
  windowScrolled: boolean;
  @Input() currentStateToTop:boolean;
  @Input() click:any;
  constructor(@Inject(DOCUMENT) private document: Document,private scroll: ViewportScroller) {

  }


  @HostListener('window:scroll', ['$event']) onScroll(event){

  }
  scrollToTop() {

    this.click.scrollTo({top:0,behavior: 'smooth'})
  }
  ngOnInit() {}
}
