import { AsyncPipe } from '@angular/common';
import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Pipe({ name: 'translateTo', pure: false })
export class TranslateToPipe implements OnDestroy, PipeTransform
{
   constructor(
      public $changeRef: ChangeDetectorRef,
      public $translate: TranslateService
   )
   {
      this._$async = new AsyncPipe(this.$changeRef);
   }

   private _$async: AsyncPipe;
   private _from: string;
   private _to: string;
   private _lang: string;
   private _translate$: Observable<string>;


   ngOnDestroy(): void
   {
      this._$async.ngOnDestroy();
   }


   private _hasChanges(from?: string, to?: string, lang?: string): boolean
   {
      return (
         from === this._from
         || to === this._to
         || lang === this._lang
      );
   }


   transform(from?: string, to?: string, lang?: string): string | null
   {
      if (!this._translate$ || this._hasChanges(from, to, lang)) {
         this._from = from;
         this._to = to;
         this._lang = lang;
         this._translate$ = this.$translate.onLangChange.asObservable().pipe(
            startWith({}),
            map(() =>
            {
               if (!to) { return from; }

               const langs = this.$translate.getLangs();
               const defaultLang = this.$translate.defaultLang;
               const currentLang = this.$translate.currentLang;

               lang = (langs.indexOf(lang) > -1 ? lang : defaultLang);

               if (lang === currentLang) { return to; }

               return from;
            })
         );
      }

      return this._$async.transform(this._translate$);
   }
}
