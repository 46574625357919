import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-movie-detail',
  templateUrl: './movie-detail.component.html',
  styleUrls: ['./movie-detail.component.scss']
})
export class MovieDetailComponent implements OnInit {
  @Input() movie: any;

  @Input()
  isComingSoon: boolean = false;

  @Output() playTrailer = new EventEmitter<string>();

  constructor() {}

  ngOnInit() {}

  onPlayTrailer($event) {
    this.playTrailer.emit($event);
  }
}
