import { Component, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

@Component({
   selector: 'app-paginator',
   templateUrl: './paginator.component.html',
   styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnChanges, OnInit, OnDestroy
{
   @HostBinding('class.app-paginator') hostCss = true;
   @Input('length') length: number = 0;
   @Input('pageSize') pageSize: number = 10;
   @Input('pageIndex') pageIndex: number = 0;
   @Input('prevHint') prevHint: string = 'Previous page';
   @Input('nextHint') nextHint: string = 'Next page';
   @Input('panelCss') panelCss?: string;
   @Output() pageChange = new EventEmitter<number>(true);


   get isFirstIndex() { return !this.length || !this.pageIndex; }

   get isLastIndex() { return !this.length || this.pageIndex === (this.lastPage - 1); }

   get currentPage() { return (!this.length ? 0 : this.pageIndex + 1); }

   get lastPage() { return Math.ceil(this.length / this.pageSize); }


   ngOnChanges({ length, pageSize, pageIndex }: SimpleChanges): void
   {
      if (length) {
         this.length = Math.max(length.currentValue || 0, 0);
      }

      if (pageSize) {
         const size = pageSize.currentValue || 10;
         this.pageSize = (size > 0 ? size : 10);
      }

      if (pageIndex) {
         this.pageIndex = Math.min(
            Math.max(pageIndex.currentValue || 0, 0),
            Math.max(this.lastPage - 1, 0)
         );
      }
   }

   ngOnInit(): void
   {
      this.pageChange.asObservable().pipe(
         untilDestroyed(this)
      ).subscribe(u => this.pageIndex = u);
   }

   ngOnDestroy(): void { }


   onPrevPageClick(): void
   {
      if (!this.isFirstIndex) {
         this.pageChange.emit(this.pageIndex - 1);
      }
   }

   onNextPageClick(): void
   {
      if (!this.isLastIndex) {
         this.pageChange.emit(this.pageIndex + 1);
      }
   }
}
