import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BannerService {
  constructor(private apiService: ApiService) {}

  getAll() {
    return this.apiService.get('https://www.majorplatinumcineplex.la/api/banner').pipe(
      map(data => {
        ////console.log(data);
        return data;
      })
    );
  }
}
