import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { API_ENDPOINT_STAGING } from '../../../shared/helpers';
import { GenericPage, PromotionCategory, PromotionInfo2 } from '../../models';
import { ApiService } from '../api.service';

@Injectable({ providedIn: 'root' })
export class Promotion2Service
{
   constructor(private $apiService: ApiService) { }

   get basePath() { return `${API_ENDPOINT_STAGING}/api`; }


   listByCategory(categoryId: number | string, params?: HttpParams): Observable<GenericPage<PromotionInfo2>>
   {
      return this.$apiService.get(`${this.basePath}/get_promotion_by_category_id?category_id=${categoryId}`, params).pipe(
         map(r =>
         {
            return {
               items: r,
               totalCount: r.length
            } as GenericPage<PromotionInfo2>;
         })
      );
   }

   read(id: number | string, params?: HttpParams): Observable<PromotionInfo2>
   {
      return this.$apiService.get(`${this.basePath}/get_promotion_by_id?id=${id}`, params);
   }


   listCategory(params?: HttpParams): Observable<PromotionCategory[]>
   {
      return this.$apiService.get(`${this.basePath}/promotion_category`, params);
   }

   readHighlight(params?: HttpParams): Observable<PromotionInfo2>
   {
      return this.$apiService.get(`${this.basePath}/get_promotion_hihglight`, params);
   }
}
