import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { untilDestroyed } from "ngx-take-until-destroy";
import { fromEvent, merge, Observable } from 'rxjs';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import { CinemaInfo } from '../../../core/models';
import { CinemaService } from '../../../core/services/http';
import { MetaService } from '../../../core/services/http';
import { ShareService } from 'src/app/shared/services';

@Component({
   selector: 'app-cinema-list',
   templateUrl: './cinema-list.component.html',
   styleUrls: ['./cinema-list.component.scss']
})
export class CinemaListComponent implements OnInit, AfterViewInit, OnDestroy
{
   @ViewChild('searchTermRef', { read: ElementRef, static: false }) $searchTermRef: ElementRef<HTMLInputElement>;
   @ViewChild('searchBtnRef', { read: ElementRef, static: false }) $searchBtnRef: ElementRef<HTMLButtonElement>;

   constructor(
      private $uri: ActivatedRoute,
      private $router: Router,
      private $cinemaService: CinemaService,
      public shareService: ShareService
   ) {
      //this.metaService.addTags('CINEMA');
   }

   private _models$: Observable<CinemaInfo[]>;

   movieModel: object;
   models$: Observable<CinemaInfo[]>;
   highlight$: Observable<CinemaInfo>;


   ngOnInit(): void
   {
      this._models$ = this.$cinemaService.list(null, true);

      this.models$ = this.$uri.queryParamMap.pipe(
         switchMap(params =>
         {
            const term = params.get('term') || '';

            return this._models$.pipe(
               map(models =>
               {
                  if (!term.length) { return models; }

                  return models.filter(u => (u.name.toLocaleLowerCase()).includes(term.toLocaleLowerCase()));
               })
            );
         })
      );
      this.highlight$ = this._models$.pipe(
         map(u => u.filter(v => v.highlight)),
         map(u => u.shift())
      );
      //this.share();
   }

   ngAfterViewInit(): void
   {
      const $searchTermNode = this.$searchTermRef.nativeElement;
      const $searchBtnNode = this.$searchBtnRef.nativeElement;

      merge(
         fromEvent($searchTermNode, 'keyup').pipe(
            debounceTime(250)
         ),
         fromEvent($searchTermNode, 'change'),
         fromEvent($searchBtnNode, 'click')
      ).pipe(
         untilDestroyed(this),
         map(() => $searchTermNode.value),
      ).subscribe(term =>
      {
         this.$router.navigate([], {
            relativeTo: this.$uri,
            queryParamsHandling: 'merge',
            queryParams: { term }
         });
      });

      this.$uri.queryParamMap.pipe(
         untilDestroyed(this)
      ).subscribe(u => $searchTermNode.value = u.get('term') || '');
   }

   ngOnDestroy(): void { }

   share() {
    /*this.highlight$.subscribe(data => {
      if (this.translate.currentLang === 'en') {
        this.movieModel = {
          title: data.name,
          image: data.highlightBanner,
          description: data.address_details
        };
      } else {
        this.movieModel = {
          title: data.nameAlt,
          image: data.highlightBannerMobile,
          description: data.address_details2
        };
      }
      this.metaService.addOGTags(this.movieModel, window.location.href);
    });*/

    this.shareService.share('https://www.majorplatinumcineplex.la/share_cinema/');
  }
}
