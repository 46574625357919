import { Component, OnInit, Input , Inject, PLATFORM_ID} from '@angular/core';
import { CinemaSystemTypeService } from 'src/app/core/services/http/cinema_system_type.service';
import { ConfigService } from 'src/app/core/services/http/config.service';
import { Observable } from 'rxjs';
import { CinemaSystemType } from 'src/app/core/models/cinema_system_type.model';
import { AppConfig } from 'src/app/core/models/app_config_model.model';
import { map } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  thisYear: Date;

  @Input() appDetail: any;
  @Input() currentState: boolean;

  appConfig$: Observable<any[]>;

  cinemaSystemType$: Observable<any>;

  constructor(private cinemaSystemTypeService: CinemaSystemTypeService,private configService: ConfigService,@Inject(PLATFORM_ID) private platformId: Object) {
    
    if (isPlatformBrowser(this.platformId)) {
      this.cinemaSystemType$ = this.cinemaSystemTypeService.info().pipe(
        map(x => {
          return Object.assign(
            {},
            {
              firstOrder: x.slice(0, 4),
              secondOrder: x.slice(4, 8)
            }
          );
        })
      );
    }
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.appConfig$ = this.configService.getAll();
    }
    this.thisYear = new Date();
  }
}
