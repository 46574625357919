import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { BannerComponent } from './components/banner/banner.component';

import { SlickCarouselModule } from 'ngx-slick-carousel';
import { MoviePosterComponent } from './components/movie-poster/movie-poster.component';
import { AdsAreaComponent } from './components/ads-area/ads-area.component';
import { MovieDetailComponent } from './components/movie-detail/movie-detail.component';
import { PromotionComponent } from './components/promotion/promotion.component';

import { SharedModule } from '../shared/shared.module';
import { MovieInfoComponent, CinemaListComponent, CinemaInfoComponent, PromotionListComponent, PromotionInfoComponent, ContactUsComponent, NewsListComponent, NewsInfoComponent,UnderconstuctionComponent } from './pages';
import { MovieDetail2Component, MoviePoster2Component, MoviePoster2HeaderComponent, MovieNavComponent, AdsPopupComponent } from './components';

@NgModule({
  declarations: [
    HomeComponent,
    BannerComponent,
    MoviePosterComponent,
    AdsAreaComponent,
    MovieDetailComponent,
    PromotionComponent,
    UnderconstuctionComponent,

    MovieInfoComponent,
    CinemaListComponent,
    CinemaInfoComponent,
    PromotionListComponent,
    PromotionInfoComponent,
    NewsListComponent,
    NewsInfoComponent,
    ContactUsComponent,

    MovieDetail2Component,
    MoviePoster2Component,
    MoviePoster2HeaderComponent,
    MovieNavComponent,
    AdsPopupComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HomeRoutingModule,
    SlickCarouselModule,

    SharedModule
  ]
})
export class HomeModule { }
